import http from '../../http'
import router from "../../router"

export const fetchUser = async ({commit}) => {
	try {
		let results = await http.auth.user()
		
		if(results.user) {
			commit('setUser', results.user)
			commit('setWallet', results.wallet)
			if(results.user.has_update) {
				commit('setHasUpdate', true)
			}
			if(results.user.black_list) {
				router.push({name: 'ErrorApp', params: {message: results.user.black_list_message || 'Usuario bloqueado'}})
				return Promise.reject(false)
			} else if(!results.user.active) {
				router.push({name: 'SessionFail'})
				return Promise.reject(false)
			}
			 
		} else {
			commit('setUser', results)
			commit('setWallet', null)
			if(results.has_update) {
				commit('setHasUpdate', true)
			}

			
		}

		return Promise.resolve(results)
	} catch (error) {
		return Promise.reject(error)
		
	}
}

export const login = async ({commit, dispatch}, payload) => {
	try {
		let results = await http.auth.login(payload)
		
		commit('setToken', results.token)
		commit('setUser', results.user)
		commit('setWallet', results.wallet)
		if(results.user.has_update) {
			commit('setHasUpdate', true)
		}
		return Promise.resolve(results)
	} catch (err) {
		return Promise.reject(err)
	}

}


export const fetchNotifications = async ({commit}, payload) => {
	try {
		let results = await http.notifications.list()
		commit('setNotifications', results)
		return Promise.resolve(results)
	} catch (err) {
		return Promise.reject(err)
	}

}

export const fetchMaterials = async ({commit}, payload) => {
	let materials = localStorage.getItem('materials') || null
	// let materials =  JSON.parse() || null
	if(materials) {
		commit('setMaterials', JSON.parse(materials))
	}
	try {
		let results = await http.materials.list()
		commit('setMaterials', results)
		return Promise.resolve(results)
	} catch (err) {
		return Promise.reject(err)
	}

}