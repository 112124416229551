import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Start from '../views/Start.vue'
import Score from '../views/Score.vue'
import ScoreTicket from '../views/ScoreTicket.vue'
import ClaimReward from '../views/ClaimReward.vue'
import Vault from '../views/Vault.vue'
import VaultDetails from '../views/VaultDetails.vue'
import UpOrder from '../views/UpOrder.vue'
import Menu from '../views/Menu.vue'
import Help from '../views/Help.vue'
import Rewards from '../views/Rewards.vue'
import Download from '../views/Download.vue'
import RegionalRanking from '../views/RegionalRanking.vue'
import SessionFail from '../views/SessionFail.vue'
import VersionFail from '../views/VersionFail.vue'
import ErrorApp from '../views/ErrorApp.vue'
import TerritorialRanking from '../views/TerritorialRanking.vue'
import AgencyRanking from '../views/AgencyRanking.vue'
import LeaderRegionRanking from '../views/LeaderRegionRanking.vue'
import LeaderTerritoryRanking from '../views/LeaderTerritoryRanking.vue'
import CorpTerritoryRanking from '../views/CorpTerritoryRanking.vue'
import CorpRegionRanking from '../views/CorpRegionRanking.vue'
import Notifications from '../views/Notifications.vue'
import Support from '../views/Support.vue'
import Material from '../views/Material.vue'
import HistoryActivations from '../views/HistoryActivations.vue'
import Maintenance from '../views/Maintenance.vue'
import middlewares from '../middleware'

import NewTicketSupport from '../views/NewTicketSupport.vue'
import MessagesTicket from '../views/MessagesTicket.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Maintenance',
  //   component: Maintenance,
  // },
  // {
  //   path: '/*',
  //   name: '404',
  //   redirect: '/'
  // },
  {
    path: '/',
    name: 'Start',
    // component: ScoreTicket,
  },
  {
    path: '/score',
    name: 'Score',
    component: ScoreTicket,
    meta: {
      middleware: [middlewares.auth]
    }
  },
  {
    path: '/claim/reward',
    name: 'ClaimReward',
    component: ClaimReward,
    meta: {
      middleware: [middlewares.auth]
    }
  },
  {
    path: '/version',
    name: 'VersionFail',
    component: VersionFail,
    
  },
  {
    path: '/errorapp',
    name: 'ErrorApp',
    component: ErrorApp,
    
  },
  {
    path: '/fail',
    name: 'SessionFail',
    component: SessionFail,
  },
  {
    path: '/*',
    name: 'Error',
    component: ScoreTicket,
    meta: {
      middleware: [middlewares.auth]
    }
  },
  // {
  //   path: '/support',
  //   name: 'Support',
  //   component: Support,
  //   meta: {
  //     middleware: [middlewares.auth]
  //   }
  // },
  // {
  //   path: '/new-tickets-support',
  //   name: 'NewTicketSupport',
  //   component: NewTicketSupport,
  //   meta: {
  //     middleware: [middlewares.auth]
  //   }
  // },
  // {
  //   path: '/messages-ticket/:ticket_id',
  //   name: 'MessagesTicket',
  //   component: MessagesTicket,
  //   meta: {
  //     middleware: [middlewares.auth]
  //   }
  // },
  
  // {
  //   path: '/vault',
  //   name: 'Vault',
  //   component: Vault,
	// meta: {
	// 	middleware: [middlewares.auth]
	// }
  // },
  // {
  //   path: '/vault/:sku',
  //   name: 'VaultDetails',
  //   component: VaultDetails,
	// meta: {
	// 	middleware: [middlewares.auth]
	// }
  // },
  // {
  //   path: '/order',
  //   name: 'UpOrder',
  //   component: UpOrder,
	// meta: {
	// 	middleware: [middlewares.auth]
	// }
  // },
  // {
  //   path: '/version',
  //   name: 'VersionFail',
  //   component: VersionFail,
    
  // },
  // {
  //   path: '/errorapp',
  //   name: 'ErrorApp',
  //   component: ErrorApp,
    
  // },
  // {
  //   path: '/fail',
  //   name: 'SessionFail',
  //   component: SessionFail,
  // },

  // {
  //   path: '/menu',
  //   name: 'Menu',
  //   component: Menu,
	// meta: {
	// 	middleware: [middlewares.auth]
	// }
  // },
  // {
  //   path: '/help/:tab?',
  //   name: 'Help',
  //   component: Help,
	// meta: {
	// 	middleware: [middlewares.auth]
	// }
  // },
  // {
  //   path: '/rewards',
  //   name: 'Rewards',
  //   component: Rewards,
	// meta: {
	// 	middleware: [middlewares.auth]
	// }
  // },
  // {
  //   path: '/ranking/regional',
  //   name: 'RegionalRanking',
  //   component: RegionalRanking,
	// meta: {
	// 	middleware: [middlewares.auth]
	// }
  // },
  // {
  //   path: '/material',
  //   name: 'Material',
  //   component: Material,
	// meta: {
	// 	middleware: [middlewares.auth]
	// }
  // },
  // {
  //   path: '/ranking/territorial',
  //   name: 'TerritorialRanking',
  //   component: TerritorialRanking,
  //   meta: {
  //     middleware: [middlewares.auth]
  //   }
  // },
  // {
  //   path: '/ranking/agency/:id?',
  //   name: 'AgencyRanking',
  //   component: AgencyRanking,
	// meta: {
	// 	middleware: [middlewares.auth]
	// }
  // },
  // {
  //   path: '/ranking/region/leader',
  //   name: 'LeaderRegionRanking',
  //   component: LeaderRegionRanking,
	// meta: {
	// 	middleware: [middlewares.auth]
	// }
  // },
  // {
  //   path: '/ranking/territory/leader',
  //   name: 'LeaderTerritoryRanking',
  //   component: LeaderTerritoryRanking,
  //   meta: {
  //     middleware: [middlewares.auth]
  //   }
  // },
  // {
  //   path: '/ranking/territory/corp/:id',
  //   name: 'CorpTerritoryRanking',
  //   component: CorpTerritoryRanking,
  //   meta: {
  //     middleware: [middlewares.auth]
  //   }
  // },
  // {
  //   path: '/ranking/region/corp/:id',
  //   name: 'CorpRegionRanking',
  //   component: CorpRegionRanking,
  //   meta: {
  //     middleware: [middlewares.auth]
  //   }
  // },
  // {
  //   path: '/notifications',
  //   name: 'Notifications',
  //   component: Notifications,
  //   meta: {
  //     middleware: [middlewares.auth]
  //   }
  // },
  // {
  //   path: '/history/activations/:id',
  //   name: 'HistoryActivations',
  //   component: HistoryActivations,
  //   meta: {
  //     middleware: [middlewares.auth]
  //   }
  // },
  
  // {
  //   path: '/',
  //   name: 'Download',
  //   component: Download
  // },
  
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
	document.querySelector('html').scrollTop = 0
    if(to.name != from.name) {
        // sto're.commit('status/showLoadingScreen')
    }

    if (to.meta.middleware) {
        const middlewareArr = Array.isArray(to.meta.middleware)
            ? to.meta.middleware
            : [to.meta.middleware]

        const context = {
            from,
            next,
            router,
            to,
        };

        const nextMiddleware = nextFactory(context, middlewareArr, 1)
        return middlewareArr[0]({ ...context, next: nextMiddleware })
    }

    return next();

});

router.afterEach((to, form, next)=> {
	document.querySelector('html').scrollTop = 0
}) 

function nextFactory (context, middlewareArr, index) {
    const subsequentMiddleware = middlewareArr[index]

    if (!subsequentMiddleware) {
      return context.next
    }

    return (param) => {
        if (param !== undefined) {
            return context.next(param)
        }
        const nextMiddleware = nextFactory(context, middlewareArr, index + 1)
        subsequentMiddleware({ ...context, next: nextMiddleware })
    }

}

export default router
