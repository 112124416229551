<script>
export default {
	data() {
		return {
			classStart: '',
		}
	},

	mounted() {
		debugger
		let vm = this
		vm.classStart = 'start--show'
	}
}
</script>
<template>
	<div class="start" :class="classStart">
		<div class="start__top">
			<!-- <img class="start__robot start__robot--left" src="/assets/pantalla_inicio/robot_pequeno_izquierda.png" alt=""> -->
			<!-- <img class="start__robot start__robot--rigth" src="/assets/pantalla_inicio/robot_pequeno_derecha.png" alt=""> -->
			<img class="start__robot start__robot--front" src="/assets/pantalla_inicio/robot_grande_central.png" alt="">

			<img class="start__gems start__gems1" src="/assets/v2/diamante_verde.png" alt="">
			<img class="start__gems start__gems2" src="/assets/v2/diamante_purpura.png" alt="">
			<img class="start__gems start__gems3" src="/assets/v2/diamante_blanco.png" alt="">
			<img class="start__gems start__gems4" src="/assets/v2/diamante_rosa.png" alt="">
		</div>
		<div class="start__bottom">
			<div class="start__content">
				<div class="start__bottom__slogan mb-2 mx-auto">
					<img class="start__bottom__slogan-pin" src="/assets/v2/logo_v2.png" alt="">
				</div>

				<div class="text-center mt-3">
					<router-link :to="{name: 'Score'}" class="btns" style="width: 180px">
						<span class="btns__text">
							INICIAR JUEGO
						</span>
						<img class="btns__bg" src="/assets/v2/boton_verde.png" alt="">
					</router-link>
				</div>
			</div>
			<!-- <div class="start__logo">
				<img src="/assets/pantalla_inicio/logo_zeus.png" alt="">
			</div> -->
		</div>
	</div>
</template>