import http from '../../http'

export const getLocalProducts = async ({commit, dispatch, state}, page = 1) => {
	try {
		if(state.query != ''){
			commit('setLocalProducts', [])
		}

		commit('setCurrentPage', page)
		let response = await http.products.list(page, state.query)
		commit('setLastPage', response.last_page)
		let products = []
		let productsDB = []
		let products_elektra  = []
		if(response.products_elektra.Products) {
			products_elektra = response.products_elektra.Products.map(p => p.result)
		}



		response.products_db.forEach(p => {
			
			let elektra = products_elektra.find(e => e.Id == p.sku)
			if(elektra) {
				p.elektra = elektra
				productsDB.push(p)
			}  else if(['card', 'card_nip', 'card_replacement'].includes(p.type)) {
				productsDB.push(p)
			}
		})

		// response.Products.forEach(p => {
		// 	if(p.result) {
		// 		products.push(p.result)
		// 	}
		// })
		// let skus = products.map(p => p.sku)
		commit('setLocalProducts', productsDB)

		// if(response.current_page < response.last_page) {
			// dispatch('getNextLocalProducts', response.current_page + 1)
			// commit('start')
		// } else {
		// 	commit('finish')
		// }

		commit('finish')
		// commit('setSkus', skus)
		// dispatch('getApiProducts', skus)

		return Promise.resolve(products)
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getNextLocalProducts = async ({commit, dispatch, state}, page = 2) => {
	try {
		if(state.query != '' && page == 1){
			commit('setLocalProducts', [])
		}

		commit('setCurrentPage', page)
		let response = await http.products.list(page, state.query)
		commit('setLastPage', response.last_page)
		let products = []
		let productsDB = []
		let products_elektra = response.products_elektra.Products.map(p => p.result)

		response.products_db.forEach(p => {
			let elektra = products_elektra.find(e => e.Id == p.sku)

			if(elektra) {
				p.elektra = elektra
				productsDB.push(p)
			} else if(['card', 'card_nip', 'card_replacement'].includes(p.type)) {
				productsDB.push(p)
			}
		})

		// response.Products.forEach(p => {
		// 	if(p.result) {
		// 		products.push(p.result)
		// 	}
		// })
		// let skus = products.map(p => p.sku)
		commit('pushLocalProducts', productsDB)

		// if(response.current_page < response.last_page) {
		// 	dispatch('getNextLocalProducts', response.current_page + 1)
		// } else {
		// 	commit('finish')
		// }
		commit('finish')
		// commit('setSkus', skus)
		// dispatch('getApiProducts', skus)

		return Promise.resolve(products)
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getApiProducts = async ({commit}, skus) => {
	try {
		let products = await http.products.elektra(skus)
		commit('setApiProducts', products)
		return Promise.resolve(products)
	} catch (error) {
		return Promise.reject(error)
	}
}