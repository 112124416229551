<script>
export default {
	data() {
		return {
			percentage: 0,
			step: 0.5,
			current_progress: 0,
			interval: null,
			show: true,
		}
	},

	methods: {
		handlerShow() {
			this.show = true
			this.runLoader()
		},

		handlerHide() {
			this.show = false
			if(this.interval) {
				clearInterval(this.interval)
			}
		},
		runLoader() {
			let vm = this
			vm.interval = setInterval(function() {
				vm.current_progress += vm.step;
				vm.percentage = Math.round(Math.atan(vm.current_progress) / (Math.PI / 2) * 100 * 1000) / 700

				if (vm.percentage >= 100){
					setTimeout(() => {
						// vm.handlerHide()
					}, 1000)
				}else if(vm.percentage >= 90) {
					this.step = 0.5
				}else if(vm.percentage >= 70) {
					this.step = 0.1
				}
			}, 100)
		}
	},
}
</script>
<template>
	<div v-if="show" class="splash-loader">
		<div class="splash-loader__content">
			<div class="splash-loader__slogan mb-3">
				<img class="splash-loader__spin" src="/assets/logo_blanco.png" alt="">
			</div>
			<div class="lds-ripple"><div></div><div></div></div>
			<h3 class="text-center splash-loader__title">CARGANDO</h3>

		</div>


		<div class="splash-loader__logo">
			<img src="/assets/logo_zeus.png" alt="">
		</div>
	</div>
</template>

<style lang="scss" scoped>
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

</style>