import axios from 'axios'
// const base = 'http://127.0.0.1:8787/api'
// const base2 = 'http://127.0.0.1:8787/api'
// const base3 = 'http://127.0.0.1:8787/api'

const base = "https://baz.sorianoariza.com.mx/api"
const base2 = "https://baz.sorianoariza.com.mx/api"
const base3 = base2

export default {
	auth: {
		login(payload) {
			return axios.post(base2 + '/auth/login', payload)
						.then(({data}) => Promise.resolve(data))
						.catch(err => Promise.reject(err))
		},

		user() {
			return axios.get(`${base}/auth/user`)
				.then(({data}) => Promise.resolve(data))
				.catch(err => Promise.reject(err))
		},

		seenReward() {
			return axios.post(`${base}/auth/user/seen/reward`)
				.then(({data}) => Promise.resolve(data))
				.catch(err => Promise.reject(err))
		},
		
		tourComplete() {
			return axios.post(base2 + '/auth/tour_complete')
			.then(({data}) => Promise.resolve(data))
			.catch(err => Promise.reject(err))
		},
		completeUpdate() {
			return axios.post(`${base2}/auth/complete_update`)
			.then(({data}) => Promise.resolve(data))
			.catch(err => Promise.reject(err))
		}
	},
	game: {
		closed() {
			return axios.get(`${base}/game/closed`)
			.then(({data}) => Promise.resolve(data))
			.catch(err => Promise.reject(err)) 
		}
	},
	stats: {
		agencyReferals(payload) {
			return axios.get(`${base}/stats/agency/referals${payload ? `/${payload}` : '' }`)
			.then(({data}) => Promise.resolve(data))
			.catch(err => Promise.reject(err))
		},
		regionReferals(payload) {
			return axios.get(`${base}/stats/region/referals`)
			.then(({data}) => Promise.resolve(data))
			.catch(err => Promise.reject(err))
		},
		territoryReferals(payload) {
			return axios.get(`${base}/stats/territory/referals`)
			.then(({data}) => Promise.resolve(data))
			.catch(err => Promise.reject(err))
		},
		territoryReferalsById(id) {
			return axios.get(`${base}/stats/territory/referals/${id}`)
				.then(({data}) => Promise.resolve(data))
				.catch(err => Promise.reject(err))
		}
	},

	notifications: {
		list() {
			return axios.get(`${base}/notifications`)
			.then(({data}) => Promise.resolve(data))
			.catch(err => Promise.reject(err))
		},

		viewed(payload) {
			return axios.post(`${base2}/notifications/viewed`, payload)
			.then(({data}) => Promise.resolve(data))
			.catch(err => Promise.reject(err))

		}
	},

	products: {
		list(page = 1, query = '') {
			return axios.get(`${base}/products?page=${[page]}&query=${query}`)
			.then(({data}) => Promise.resolve(data))
			.catch(err => Promise.reject(err))
		},

	

		detail(sku) {
			return axios.get(`${base}/products/${sku}`)
			.then(({data}) => Promise.resolve(data))
			.catch(err => Promise.reject(err))
		}
	},

	states: {
		list() {
			return axios.get(`${base}/states`)
			.then(({data}) => Promise.resolve(data))
			.catch(err => Promise.reject(err))
		},
	},

	orders: {
		create(payload) {
			return axios.post(`${base}/orders`, payload)
			.then(({data}) => Promise.resolve(data))
			.catch(err => Promise.reject(err))
		},
		claimReward(payload) {
			return axios.post(`${base}/orders/reward`, payload)
			.then(({data}) => Promise.resolve(data))
			.catch(err => Promise.reject(err))
		},
		simulation(payload) {
			return axios.post(`${base}/orders/simulation`, payload)
			.then(({data}) => Promise.resolve(data))
			.catch(err => Promise.reject(err))
		},
		list(payload) {
			return axios.get(`${base}/orders`)
			.then(({data}) => Promise.resolve(data))
			.catch(err => Promise.reject(err))
		},
		confirm(id) {
			return axios.post(`${base2}/orders/confirm/${id}`)
			.then(({data}) => Promise.resolve(data))
			.catch(err => Promise.reject(err))
		}
	},

	postaCode: {
		get(postalCode) {
			return axios.get(`${base}/postal_code/${postalCode}`)
						.then(({data}) => Promise.resolve(data))
						.catch(err => Promise.reject(err))
		}
	},

	materials: {
		list() {
			return axios.get(`${base}/materials`)
			.then(({data}) => Promise.resolve(data))
			.catch(err => Promise.reject(err))
		}
	},

	dailyReferals: {
		byUser(user_id) {
			return axios.get(`${base}/daily/referals/${user_id}`)
						.then(({data}) => Promise.resolve(data))
						.catch(err => Promise.reject(err))
		}
	},

	territories: {
		list() {
			return axios.get(`${base3}/territories`)
					.then(({data}) => Promise.resolve(data))
					.catch(err => Promise.reject(err))
		},

		
	},

	regions: {
		byTerritory(id) {
			return axios.get(`${base3}/territories/${id}/regions`)
					.then(({data}) => Promise.resolve(data))
					.catch(err => Promise.reject(err))
		},
	},
	agencies: {
		byRegion(id) {
			return axios.get(`${base3}/regions/${id}/agencies`)
					.then(({data}) => Promise.resolve(data))
					.catch(err => Promise.reject(err))
		},
		ektFind(params) {
			return axios.get(`${base}/agencies/ekt/find`, {
				params
			})
				.then(({data}) => Promise.resolve(data))
				.catch(err => Promise.reject(err))
		},
	},

	support:{
		tickets(user_id){
			return axios.get(`${base2}/support/tickets/${user_id}`)
						.then(({data}) => Promise.resolve(data))
						.catch(err => Promise.reject(err))
		},
		createTicket(user_id, payload){
			return axios.post(`${base2}/support/create-ticket/${user_id}`, payload)
			.then(({data}) => Promise.resolve(data))
			.catch(err => Promise.reject(err))
		},
		getCommentsTicker(ticket_id){
			return axios.get(`${base2}/support/ticket/${ticket_id}`)
						.then(({data}) => Promise.resolve(data))
						.catch(err => Promise.reject(err))
		}
	},

	params: {
		get(slug) {
			return axios.get(`${base}/params/${slug}`)
					.then(({data}) => Promise.resolve(data))
					.catch(err => Promise.reject(err))
		}
	},


	faq: {
		all() {
			return axios.get(`${base}/faqs`)
					.then(({data}) => Promise.resolve(data))
					.catch(err => Promise.reject(err))
		}
	},

	surveys: {
		all() {
			return axios.get(`${base}/surveys`)
					.then(({data}) => Promise.resolve(data))
					.catch(err => Promise.reject(err))
		},
		complete(payload) {
			return axios.post(`${base}/surveys/complete`, payload)
					.then(({data}) => Promise.resolve(data))
					.catch(err => Promise.reject(err))
		},
	},
	
	challenges: {
		current() {
			return axios.get(`${base}/challenges/current`)
					.then(({data}) => Promise.resolve(data))
					.catch(err => Promise.reject(err))
		},
		ranking(page) {
			return axios.get(`${base}/challenges/ranking?page=${page}`)
					.then(({data}) => Promise.resolve(data))
					.catch(err => Promise.reject(err))
		},
		myRanking() {
			return axios.get(`${base}/challenges/my/ranking`)
					.then(({data}) => Promise.resolve(data))
					.catch(err => Promise.reject(err))
		},
	},

	
}