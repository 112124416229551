import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueCarousel from 'vue-carousel';
import Notifications from 'vue-notification';
const moment = require('moment')
import Multiselect from 'vue-multiselect'
import { VueMaskDirective } from 'v-mask'

Vue.directive('mask', VueMaskDirective);
var _ = require('lodash/core');

require('moment/locale/es')
 
Vue.use(require('vue-moment'), {
    moment
})

Vue.use(Notifications);
Vue.use(VueCarousel);
Vue.component('multiselect', Multiselect)
// function getCookie(cname) {
//     let name = cname + "=";
//     let decodedCookie = decodeURIComponent(document.cookie);
//     let ca = decodedCookie.split(';');
//     for(let i = 0; i <ca.length; i++) {
//       let c = ca[i];
//       while (c.charAt(0) == ' ') {
//         c = c.substring(1);
//       }
//       if (c.indexOf(name) == 0) {
//         return c.substring(name.length, c.length);
//       }
//     }
//     return "";
// }

// window.getCookie = getCookie

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.interceptors.request.use(function (config) {
	
    let token = localStorage.getItem('token') || null
    let auth = {}
    if(token) {
        auth = { Authorization: 'Bearer ' + token }
    }



    // Do something before request is sent
    config.headers.common = {
        ...config.headers.common,
        ...auth,
        ...{
            'x-app-version': 2147483637
        }
    }
    return config
}, function (error) {
    // Do something with request error
    return Promise.reject(error)
})
// Add a response interceptor


window.introJs = require('intro.js')
window.Swipe = require('swipejs')

window.Popper = require('@popperjs/core')
window.jQuery = require('jquery')
window.$ = window.jQuery

require('bootstrap')

Vue.config.productionTip = false

Vue.mixin({
    methods: {
        forceHTTPS(url) {
            let new_url = url.replace('http:', 'https:')

            return new_url
        }
    }

})

let myApp = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

axios.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
}, function (error) {
    if(error.response.status == 409) {
        router.push({name: 'VersionFail'})
    }
    if(error.response.status == 403 && error.response.data.error_code == 'blacklist') {
        router.push({name: 'ErrorApp', params: {
            message: error.response.data.message || 'Usuario bloqueado'
        }})
    }
    // let vue = myApp
    // Do something with response error
    // return Promise.reject(error);
});

// document.documentElement.addEventListener('touchmove', function (event) {
//     event.preventDefault();
// }, false);