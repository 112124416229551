<script>
import { mapActions, mapGetters } from 'vuex'
import Loader from '@/components/Loader.vue'
import ModalVue from '../components/ModalVue.vue'
import CardActivations from '../components/CardActivations.vue'
import ActivationsRanking from './Challenges/ActivationsRanking.vue'
import TransactionsChallenge from './Challenges/TransactionsChallenge.vue'
import http from '../http'
export default {
	components: {
		Loader,
		ModalVue,
		CardActivations,
		ActivationsRanking,
		TransactionsChallenge
	},
	data() {
		return {
			savingSurvey: false,
			generalRank: 1000,
			totalGeneralRank: 3500,
			localRank: 800,
			totalLocalRank: 1500,
			swipe: null,
			swipeIndex: 0,
			slideVisibility: true,
			currentStep: 0,
			showBadges: false,
			slideClossing: false,
			endIntro: false,
			showIntro: false,
			introStep: 0,
			faq: false,
			surveys: [],
			challeng: null,
			gameClosed: {
				closed: true,
				message: ''
			},
			challenges: [],
			boxBaraja: true,
			showFirstNotice: false,
			loading: true
		}
	},

	computed: {
		progressGeneral() {
			return this.generalRank/this.totalGeneralRank*100
		},
		progressLocal() {
			return this.localRank/this.totalLocalRank*100
		},
		statsRegion() {
			return this.regionReferalsStats(this.user.id)
		},
		statsTerritory() {
			return this.territoryReferalsStats(this.user.id)
		},
		firstName() {
			
			if(this.user == null) return ''
			console.log(this.user.name)
			let name = this.user.name.split(' ')
			return name[name.length-1]
		},

		intro() {
			if(!this.intros.length) {
				return null
			} 

			if(this.introStep > this.intros.length - 1) { 
				return null
			}

			let intro = this.intros[this.introStep]
			if(!intro) {
				return null
			}
			return intro
		},

		currentSurvey() {
			if(!this.surveys.length) {
				return null
			}
			return this.surveys[0]
		},
		showResultWinner() {
			if(!this.user) {
				return false
			}

			return this.user.show_result_winner
		},
		...mapGetters({
			wallet: 'auth/wallet',
			user: 'auth/user',
			badge: 'auth/badge',
			regionReferalsStats: 'stats/regionReferalsStats',
			territoryReferalsStats: 'stats/territoryReferalsStats',
			agencyReferalsStats: 'stats/agencyReferalsStats',
			leaderRegionReferalsStats: 'stats/leaderRegionReferalsStats',
			leaderTerritoryReferalsStats: 'stats/leaderTerritoryReferalsStats',
			corpTerritoryReferalsStats: 'stats/corpTerritoryReferalsStats',
			territories: 'stats/territories',
			// intro: 'auth/intro',
			intros: 'auth/intros'
		})
	},

	watch: {
		intros: {
			handler(nval) {
				let vm = this
				// console.log(slideViewed)
				vm.showIntro = nval.length > 0
				if(vm.user.role_id != 6 || vm.user.role_id != 2) {
					if(!this.intro && !vm.user.tour_complete) {
						this.runTour()
					}
				}
			},
			immediate: true,
			deep: true
		}
	},

	methods: {
		async fetchChallenges() {
			let challenges = await http.challenges.current()
			this.challenges = challenges
			return Promise.resolve(challenges)
		},
		async getGameClosed() {
			let closed = await http.game.closed()
			this.$set(this, 'gameClosed', closed)
			return Promise.resolve(closed)
		},
		nextIntro() {
			let intro = this.intro;

			let materialsViewed = localStorage.getItem('materials_viewed')
			if(!materialsViewed) {
				materialsViewed = []
			} else {
				materialsViewed = JSON.parse(materialsViewed)
			}
			if(!intro.every_day) {
				materialsViewed.push(intro.id)
			}

			localStorage.setItem('materials_viewed', JSON.stringify(materialsViewed))

			if(this.introStep < this.intros.length - 1) {
				this.introStep += 1
			} else if(!this.user.tour_complete) {
				this.showIntro = false
				this.runTour()
			}  else {
				this.showIntro = false
			}


		},
		handlerEndIntro() {
			this.endIntro = true
		},
		runTour() {
			this.showIntro = false
			this.currentStep = 1
		},
		closeSlide() {
			let vm = this
			vm.slideClossing = true

			setTimeout(() => {
				vm.showBadges = false
				vm.swipeIndex = 0
			}, 1000)
		},
		floor(d) {
			return Math.floor(d)
		},
		nextStep() {
			let vm = this
			this.currentStep++
			if(this.user.role_id == 5 ) {
				if(this.currentStep == 4) {
					this.currentStep += 1
				}
			} else if(this.user.role_id == 4 && this.user.region_id) {
				if(this.currentStep == 4) {
					this.currentStep += 1
				}
			} else if(this.user.role_id == 4 && !this.user.region_id) {
				if(this.currentStep == 3) {
					this.currentStep += 1
				}
			}
			if(this.currentStep > 5) {
				this.currentStep = 0
				http.auth.tourComplete()
				let user = JSON.stringify(this.user)
				user = JSON.parse(user)
				user.tour_complete = 1
				// window.user = user
				sessionStorage.setItem('user', JSON.stringify(user))
				// document.querySelector('.app-back').classList.remove('d-none')
			}

			this.$nextTick(() => {
				try {
					
					let $step = document.getElementById('step' + vm.currentStep)
		
					if($step) {
						$step.scrollIntoView();
					}
				} catch (error) {
					
				}
			})

		},

		handlerShowBadges() {
			let vm =  this
			vm.showBadges = true
			vm.$nextTick(() => {
				var element = document.getElementById('slider');
				vm.slideClossing = false
				vm.swipe = new window.Swipe(element, {
					startSlide: 0,
					auto: 0,
					draggable: false,
					autoRestart: false,
					continuous: false,
					disableScroll: true,
					stopPropagation: true,
					callback: function(index, element) {
						vm.swipeIndex = index
					},
					transitionEnd: function(index, element) {}
				});
			})
		},
		calcBar(stats) {
			let point = 100 / stats.total_referals 
			return 	stats.me_referals * point
		},

		goTo(data) {
			this.$router.push(data)
		},
		async checkFaq() {
			try {
				let data = await http.params.get('show_faq_btn')
				this.faq = data.value
			} catch (error) {
				
			}
		},
		async checkParams(name) {
			try {
				let data = await http.params.get(name)
				return data
			} catch (error) {
				return null
			}
		},

		async fetchSurveys() {
			try {
				let data = await http.surveys.all()
				data.forEach(survey => {
					survey.questions.forEach(q => {
						q.data.answer = []
					})
				});
				this.$set(this, 'surveys', data)
			} catch (error) {
				
			}
		},

		async seveSurvey() {
			let payload = {
				survey_id: this.currentSurvey.id,
				questions: []
			}

			this.currentSurvey.questions.forEach(question => {
				let prepareData = {
					answer: [],
					aditional_answer: ''
				}

				prepareData.answer = question.data.answer
				if(question.data.answer.includes('aditional')) {
					prepareData.aditional_answer = question.data.aditional.answer
				}

				payload.questions.push({
					id: question.id,
					statement: question.statement,
					answer: prepareData.answer,
					aditional_answer: prepareData.aditional_answer
				})

			})

			try {
				if(this.savingSurvey) {
					return
				}
				this.savingSurvey = true
				let data = await http.surveys.complete(payload)
				this.savingSurvey = false
				this.$refs.survey.close()
				this.fetchSurveys()
			} catch (error) {
				
			}
		},

		async getChalleng() {
			try {
				let data = await http.challenges.current()
				this.$set(this, 'challeng', data )
			} catch (error) {
			}
		},
		async setSeenReward() {
			let vm = this
			try {
				debugger
				vm.$refs.splashLoader.handlerShow()
				let data = await http.auth.seenReward()
				await vm.fetchUser()
				vm.$refs.splashLoader.handlerHide()
			} catch (error) {
				vm.$refs.splashLoader.handlerHide()
			}
		},
		...mapActions({
			fetchUser: 'auth/fetchUser',
			fetchRegionReferals: 'stats/fetchRegionReferals',
			fetchTerritoryReferals: 'stats/fetchTerritoryReferals',
			fetchAgencyReferals: 'stats/fetchAgencyReferals',
			fetchTerritories: 'stats/fetchTerritories',
			fetchNotifications: 'auth/fetchNotifications'
		})
	},

	async mounted() {
		// this.getChalleng()
		this.fetchSurveys()
		// this.checkFaq()
		let session = await this.fetchUser()
		// let challenges = await this.fetchChallenges()
		let gameClosed = await this.getGameClosed()
		let first_notice = await this.checkParams('first_notice')
		this.showFirstNotice = first_notice.value == 1
		this.$refs.splashLoader.handlerHide()
		setTimeout(() => {
			this.loading = false
		}, 500);
		if(this.gameClosed.closed == true) {
			return
		} else {
			console.log('sigue')
			let now = this.$moment()
			console.log(now)
			let last_activations_update = localStorage.last_activations_update ? this.$moment(localStorage.last_activations_update) : null
			
	
			if(this.user.role_id == 6 || this.user.role_id == 2 || this.user.role_id == 9) {
				this.fetchTerritories()
			}
	
			if(last_activations_update) {
				var duration = this.$moment.duration(now.diff(last_activations_update));
				console.log(now)
				console.log(last_activations_update)
				console.log(duration.asMinutes())
		
				// localStorage.last_activations_update = now
				
				if(duration.asMinutes() < 20) {
					return 
				} 
			}
	
	
	
			
			// this.fetchNotifications()
			if(this.user.role_id == 3) {
				this.fetchRegionReferals().then(r => {
					localStorage.last_activations_update = now
				})
				this.fetchTerritoryReferals().then(r => {
					localStorage.last_activations_update = now
				})
			} else if(this.user.role_id == 5) {
				this.fetchAgencyReferals().then(r => {
					localStorage.last_activations_update = now
				})
			} else if(this.user.role_id == 4) {
				if(this.user.region_id) {
					this.fetchRegionReferals().then(r => {
						localStorage.last_activations_update = now
					})
				} else if (this.user.territory_id) {
					this.fetchTerritoryReferals().then(r => {
						localStorage.last_activations_update = now
					})
				}
			} 
	
	
			var element = document.getElementById('slider');
		}


	
		
		
	},

}
</script>
<template>
	<div class="score" v-if="user && (user.reward_block)">
		<Loader  ref="splashLoader"/>
		<modal-vue ref="survey" v-if="currentSurvey" :opening="true" :can-close="false">
			<template v-slot:header>
				<h5 class="m-0">{{currentSurvey.title}}</h5>
			</template>
			<template v-slot:body>
				<p class="text-center">{{currentSurvey.description}}</p>
				<div v-for="(question, indexq) in currentSurvey.questions" :key="`q-${indexq}`">
					<div>
						<div class="col-12" v-if="question.type == 'text'">
							<div class="vault__search flex-wrap mt-1">
								<label class="w-100 d-block ms-2" for="">{{indexq + 1}} - {{question.statement}}</label>
								<div class="vault__search-input">
									<input type="text" v-model="question.data.answer">
								</div>
							</div>
						</div>
						<div class="col-12" v-else>
							<div class="vault__search flex-wrap mt-1">
								<h6 class="w-100 d-block" for="">{{indexq + 1}} - {{question.statement}}</h6>
								<div class="ps-3">
									<label v-for="(option, index) in question.data.options" :key="`op-${index}`" class="d-block">
										<input :type="question.type" :name="`option-select-${indexq}`" v-model="question.data.answer" :value="option.name">
										<span class="ms-2 text-bold" style="font-weight: bold; font-size: 0.9rem">{{option.name}}</span>
									</label>
									<label v-if="question.data.aditional.active" class="d-block">
										<input :type="question.type" :name="`option-select-${indexq}`" v-model="question.data.answer" :value="`aditional`">
										<span class="ms-2 text-bold" style="font-weight: bold; font-size: 0.9rem">{{question.data.aditional.statement}}</span>
									</label>
								</div>
							</div>
						</div>
						<div class="col-12" v-if="question.data.answer.includes('aditional')">
							<div class="vault__search flex-wrap mt-1">
								<div class="vault__search-input">
									<input type="text" v-model="question.data.aditional.answer">
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="py-2 d-flex">
					<button class="btn btn--rounded btn-primary fw-bold me-auto ms-auto" @click="seveSurvey">Continuar</button>
				</div>
			</template>
		</modal-vue>
		<template v-if="!(showIntro ? intro.type == 'video' : false)">
		<div id="step1" class="p-0" :class="{
			'score__step-active': currentStep == 1
		}">
			<div class="score__wellcome text-center pb-3 pt-4">
				<h2 class="app-title mb-0 text-white">¡Hola <br>
					{{firstName}}!</h2>
				<h2 class="mb-0 text-white">{{user.code}}</h2>
				<!-- <h2 class="sub-title mb-0 text-white"><b>Las Gemas de la Suerte</b></h2> -->
			</div>
			<!-- <div class="score__step-tip step__1" >
				<img src="/assets/steps/1.png" alt="">
			</div> -->
		</div>
		<!-- <div class="bg-primary text-white p-2 small text-center" role="alert">
			<p>¡Tus activaciones están seguras y las verás reflejadas junto con tus gemas muy pronto!</p>
			<p class="m-0">Sigue activando y recuerda, <span class="fw-bold"> con baz súperapp ¡Vamos por todo, Todos!</span></p>
		</div> -->
		<div v-if="showResultWinner">
			<div v-if="user.reward_block">
				<div  class="score__badge text-center score-text" 
					:class="{
						'mb-4': boxBaraja === true
					}" 
				>
					<p class="text-subtitle px-5 pt-3">Cada una de tus gemas se convirtió en un boleto.</p>
					<p class="text-subtitle px-4 mt-3"><b>Participaste con:</b></p>
				</div>
				<div class="px-3">
					<div class="tickets">
						<img src="/assets/v2/bg-ticket.png" class="tickets-bg">
						<div class="tickets-balance">
							<div>
								<span class="tickets-count">{{ wallet.balance }}</span>
								<span class="tickets-label">{{  wallet.balance > 1 ? 'boletos': 'boleto' }}</span>
							</div>
						</div>
					</div>
				</div>
				
				<div class="score__badge score-text mb-0 pb-5 mt-3">
					<p class="text-subtitle px-4 pt-3 text-center" >Descubre si fuiste uno de los ganadores.</p>
	
	
					<div class="text-center mb-3">
						<button class="tickets-btn" @click="setSeenReward">
							<img src="/assets/v2/btn-clic.png" alt="">
						</button>
					</div>
				</div>
			</div>
			
		</div>
		<div v-else class="score__wrapper">
			<div v-if="gameClosed.closed == true && !showFirstNotice" id="step2" class="score__badge" :class="{
					'score__step-active': currentStep == 2
				}">
				<img v-if="badge"  class="score__badge-medal" :src="`/img/badges/diamante_plata.png`" alt="" @click="handlerShowBadges">
				<img class="score__badge-bg" src="/assets/v2/badge_bg.png">
				
				<!-- <div class="score__step-tip step__2" >
					<img src="/assets/steps/2.png" alt="">
				</div> -->
			</div>
			<div v-if="gameClosed.closed == true && showFirstNotice" class="score__badge text-center score-text" 
				:class="{
					'mb-4': boxBaraja === true
				}" 
			>
				<p class="text-subtitle px-2">¡Le decimos <b>adiós al</b> <b class="text-pink">Juego de Gemas</b> <br> con un <b>gran sorteo!</b></p>
				<p class="text-subtitle px-0 mt-3">Participas con tu <b>saldo actual de Gemas</b> que es:</p>
			</div>
			
			<div class="px-2 pt-1 pb-1 mt-2">

				<!-- <div v-if="challenges.length">
					<div v-for="(challenge, index) in challenges" :key="`challenge-${index}`">
						<component :is="challenge.component" :challenge="challenge"></component>
					</div>
				</div> -->
				<div v-if="gameClosed.closed == true && !showFirstNotice" class="box-diamante px-3 pb-3 pt-3 mb-5">
					<p class="text-center" v-html="gameClosed.message"></p>
				</div>
				
				<div class="text-center" v-if="gameClosed.closed == true && showFirstNotice">
					<span class="my-gems">{{ wallet.balance }}</span>

					<p class="mt-4 mb-0 text-green text-subtitle">
						<b>1 Gema = 1 Boleto</b>
					</p>
					<p class="mt-1  text-subtitle">
						<b>¡Mucho éxito!</b>
					</p>
				</div>
				<!-- <div v-if="wallet" class="text-center mt-3 mb-5">
					<router-link :to="{name: 'Vault'}" class="btns" style="width: 250px">
						<span class="btns__text">
							VER LOS PREMIOS
						</span>
						<img class="btns__bg" src="/assets/v2/boton_rosa.png" alt="">
					</router-link>
				</div> -->

				<!-- <div class="score__logo mt-3 mb-5">
					<img src="/assets/logo_zeus_.png" alt="">
				</div> -->
			</div>
			
		</div>

		

		<modal-vue ref="alertModal">
			<template v-slot:header>
				<h5 class="m-0">Recuerda</h5>
			</template>
			<template v-slot:body>
				<h6 class="text-center fw-bold">Recuerda que hoy finaliza esta etapa del juego. </h6>
				<p class="text-center">Apresúrate a activar hasta las 23:59 del día de hoy y acumular más gemas.  <span class="text fw-bold">¡Mucho éxito!</span></p>
				<div class="py-2 d-flex">
					<button class="btn btn--rounded btn-primary fw-bold me-auto ms-auto" @click="$refs.alertModal.close">Continuar</button>
				</div>
			</template>
		</modal-vue>

		<button @click="goTo({name: 'Help', params: {tab: 'faq'}})"  v-if="faq" class="btn btn-primary btn-sm btn--rounded btn-add-ticket"><span>?</span></button>
		</template>
		<template v-else>
				<div>
					<div class="score__wellcome text-center pb-3">
					<h2 class="sub-title mb-0 text-white">Bienvenido {{firstName}}.</h2>
					<h5 class=" mb-0 text-white small">{{user.code}}</h5>
				</div>
				<div class="score_video" style="z-index: 99; position: relative; margin-top: 50px">
					<h6 class="text-center title " style="font-size: 1rem">{{intro.title}}</h6>
					<div class="d-flex">
						<div v-if="!intro.is_vertical" class="mx-auto" style="max-width: 100%; min-width: 90%">
							<div style="padding:56.25% 0 0 0;position:relative;"><iframe :src="intro.url"  frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" :title="intro.title"></iframe></div>
						</div>
						<div v-else class="mx-auto" style="max-width: 100%; min-width: 90%">
							<div >
								<iframe :src="intro.url"  frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="width:100%;height:70vh;" :title="intro.title"></iframe>
							</div>
						</div>
					</div>
					<div class="text-center py-2">
						<button  @click="nextIntro" class="btn btn-primary fw-bold btn--rounded">Continuar</button>
					</div>
				</div>
			</div>
		</template>
	</div>
	<div class="score score-v2" :class="{'score-v2': user.winner, 'score-v3': !user.winner}" v-else-if="user && !user.reward_block && showResultWinner">
		<Loader  ref="splashLoader"/>
		<div class="p-0" v-if="user.winner">
			<div class="score__wellcome text-center pb-3 pt-4" >
				<img class="reward__cup" src="/assets/v2/Elementos_Trofeo.png" alt="">
				<h2 class="mb-0 text-white">{{user.code}}</h2>
				<!-- <h2 class="sub-title mb-0 text-white"><b>Las Gemas de la Suerte</b></h2> -->
			</div>
			<div  class="score__badge text-center score-text mt-2 pb-5" style="margin-bottom: 0 !important;">
				<p class="app-title px-0 pt-0 text-pink " style="margin-bottom: 0 !important;"><b>¡Felicidades!</b></p>
				<p class="text-subtitle px-4 pt-0 mt-0">Eres el ganador de:</p>

				<div class="px-3">
					<div class="reward__name">
						<h2 class="m-0">{{ user.winner.reward_name }}</h2>
					</div>
				</div>
				<div v-if="user.winner.available">
					<p class="text-subtitle px-5 mt-3">Completa la información para el envío de tu premio.</p>
					<p class="text-subtitle px-5 mt-3"><b class="text-pink">¡Hazlo ahora mismo!</b></p>
	
					<div class="text-center mb-3 mt-3">
						<router-link :to="{name: 'ClaimReward'}" class="tickets-btn" >
							<img src="/assets/v2/btn-clic.png" alt="">
						</router-link>
					</div>
				</div>
				<div v-else>
					<p class="text-subtitle px-5 mt-3">Estamos preparando el envío de tu premio<br> ¡Tu premio será entregado en un <b>lapso de 2 a 3 semanas!</b>  </p>
				</div>
			</div>
			
		</div>
		<div class="p-0" v-else>
			<div class="score__wellcome text-center  py-5" style="padding-top: 7rem !important;" >
				<h2 class="app-title mb-0 text-white" style="font-weight: 400; font-size: 2.5rem;">¡Gracias por</h2>
				<h2 class="app-title mb-0 text-white" style="font-weight: 400; font-size: 3rem;"><b>participar!</b></h2>
			</div>
			<div  class="score__badge text-center score-text mt-4 pb-5 pt-4" style="margin-bottom: 0 !important;">
				<p class="app-title px-5 pt-0" style="margin-bottom: 0 !important; font-weight: 400; color: #000;">
					<b>No fuiste ganador</b> 
 				del <b class="text-green">Juego de Gemas.</b> 
				</p>
				
			</div>
			
		</div>
	</div>
</template>

<style lang="scss" scoped>
.reward{
	&__cup{
		width: 80%;
		display: block;
		margin: 0 auto;
	}
	&__name{
		background-color: #36a936;
		color: #fff;
		padding: 1rem 0.5rem;
		border-radius: 1rem;

	}
}
.tickets{
	position: relative;
	&-btn{
		background: transparent;
		padding: 0;
		outline:  none !important;
		border: none !important;
		width: 200px;
		margin: 0 auto;
		display: inline-block;
		img{
			width: 100%;
		}
	}
	&-bg{
		position: relative;
		width: 100%;
	}
	&-balance{
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		color: #fff;
		font-weight: 700;
		z-index: 2;
		
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
	}
	&-label{
		font-size: 1.5rem;
		width: 100%;
		text-align: center;
		height: 1.8rem;
		line-height: 1.8rem;
		display: block;
	}
	&-count{
		display: block;
		font-size: 6rem;
		width: 100%;
		text-align: center;
		height: 6rem;
		line-height: 6rem;
	}

}
.text-subtitle{
	font-size: 1.5rem;
	color: #000;
}
.text-pink{
	color: #ff00be;
}
.text-green{
	color: #00c22d;
}
.score{
	&__wellcome{
		padding-left: 0.25rem !important;
		padding-right: 0.25rem !important;
		padding-top: 2rem !important;
		max-width: 80%;
		margin: 0 auto;
	}
}
.app-title{
	font-weight: 700;
	font-size: 2.8rem;
	padding: 0;
}
.score_video{
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	video{
		max-height: 40vh;
	}
}

.btn-add-ticket{
    position: fixed;
    bottom: 30px;
    right: 30px;
    -webkit-animation: buttonsupport2 1.5s ease-in-out infinite;
    animation: buttonsupport2 1.5s ease-in-out infinite;
	z-index: 2;
	border-radius: 50%;
	height: 2.5rem;
	width: 2.5rem;
}
@keyframes buttonsupport2{
	0%{
		box-shadow: 0 0 0 0px rgba($color: #6807d6, $alpha: 1.0);
	} 

	100%{
		box-shadow: 0 0 0 15px rgba($color: #6807d6, $alpha: 0);
	}
}

.btn-add-ticket span{
    font-weight: bold;
    font-size: 20px;
	display: inline-block;
}

.btn-primary{
	background-color: rgba(#ffffff, 0.8) !important;
	border-radius: 10px;
	color: purple !important;
	font-weight: bold;
	padding: 5px 30px;
	width: 75%;
}

.box{
	&-container-ticket{
		/* height: 60vh;
		overflow: auto; */
		padding-top: 15px;
		padding-bottom: 15px;
		position: relative;
		.btn_view_more{
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: 999;
			opacity: 1.0;
			// backdrop-filter: blur(1px);
			button {
				border: 0;
				background-color: transparent;
				position: absolute;
				top: 75.5%;
				left: 50%;
				transform: translate(-50%, -50%);

				img {
					margin: 0;
				}
			}
		}

	}
	/* &-tickets{
		position: absolute;
		height: 100%;
		width: 100%;
	} */
	&-ticket{
		background-image: url('/assets/v2/boleto.png');
		background-size: 100% 100%;
		height: 170px;
		margin-top: -1px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		&-baraja{
			margin-top: -170px;
			/* transform-origin: 10%;
			transform: rotate(270deg); */
			&-opa{
				opacity: 0.4;
			}
		}
		&-gema{
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			color: white;
			font-weight: bolder;
			font-size: x-large;

			img {
				transform: rotate(15deg);
			}
		}
		&-info{
			text-align: center;
			width: 60%;
			p{
				background-color: white;
				padding: 10px 20px 10px 20px;
				border-radius: 10px;
				font-weight: bold;
				color: #8845CE;
			}
		}
	}
}

.dashed-ticket{
	overflow: hidden;
	position: relative;
	z-index: 2;
	&:before {
		z-index: 1;
		content: "";
		position: absolute;
		border-bottom: 5px dashed #CEF46D;
		top: -1px;
		bottom: -1px;
		left: -1px;
		right: -1px;
		width: 80%;
		margin: 0 auto;
	}
	> div {
        z-index: 2;
    }
}

.z-20{
	position: relative;
	z-index: 20 !important;
	transform: rotate(270deg);
}

.first-cards{
	transform: rotate(260deg);
}


.score{
	background-image: url('/assets/v2/bg-top.png');
	&-v2{
		background-image: url('/assets/v2/bg_top_v2.png');
	}
	&-v3{
		background-image: url('/assets/v2/bg_top_v3.png');
	}
	&-text{
		color: #8200FF;
		font-weight: 500;
	}
}

.my-gems{
	display: inline-block;
	margin: 0 auto;
	background-image: url('/assets/v2/bg-gems.png');
	background-size: 100% 100%;
	color: #fff;
	font-weight: 700;
	padding: 0.5rem 3.5rem;
	font-size: 3.5rem;
}
.score__badge{
	max-width: 100%;
}

.img-size{
	width: 15%;
}
</style>