<script>
import Loader from '@/components/Loader.vue'
import SessionFail from './views/SessionFail.vue'
import CryptoJS  from 'crypto-js'
import  {mapActions, mapGetters} from 'vuex'
import http from './http'
import ModalVue from './components/ModalVue.vue'

export default {
	components: {
		Loader,
		SessionFail,
		ModalVue
	},

	data() {
		return {
			showMenu: false,
			loading: true,
			support: false,
		}
	},
	computed: {
		...mapGetters({
			user: 'auth/user',
			hasUpdate: 'auth/hasUpdate'
		})
	},
	methods: {
		handlerShowMenu() {
			this.$router.push({name: 'Menu'})
		},
		hiddenLoading() {
			let vm = this
			this.$refs.splashLoader.handlerHide()
			setTimeout(() => {
				vm.loading = false
			})

		},
		handlerUpdate() {
			
			http.auth.completeUpdate(this.user.id).then(res => {
				window.location.reload(true)

			}).catch(err => {
				window.location.reload(true)
			})
		},
		go(name) {
			this.$router.push({name: name})
		},

		async checkSupport() {
			try {
				let data = await http.params.get('support_active')

				this.support = data.value
			} catch (error) {
				
			}
		},
		
		...mapActions({
			login: 'auth/login',
			fetchUser: 'auth/fetchUser',
			fetchMaterials: 'auth/fetchMaterials',
		})
	},

	mounted() {
		debugger
		// var ciphertext = CryptoJS.AES.encrypt('1023872', 'RaG&j!9G2zczJbka').toString();


		let vm = this
		
		let code = this.$route.query.hasOwnProperty('code') ? this.$route.query.code : null
		debugger

		if(code) {
			try {
				
				var ciphertext = CryptoJS.enc.Hex.parse(code);
				var pwhash = CryptoJS.SHA1(CryptoJS.enc.Utf8.parse("RaG&j!9G2zczJbka"));
				var key = CryptoJS.enc.Hex.parse(pwhash.toString(CryptoJS.enc.Hex).substr(0, 32));
				let text = pwhash.toString(CryptoJS.enc.Hex).substr(0, 32)
				var decrypted = CryptoJS.AES.decrypt({
					ciphertext: ciphertext
				}, key, {
					mode:     CryptoJS.mode.ECB,
					padding:  CryptoJS.pad.Pkcs7
				});
		
				var plaintext = decrypted.toString(CryptoJS.enc.Utf8);
				code = plaintext
	
				if(plaintext ) {
					localStorage.setItem('code', plaintext)
				}
			} catch (error) {
				
			}
		} else {
			code = localStorage.getItem('code')
		}

		this.$refs.splashLoader.handlerShow()
		vm.fetchUser().then(res => {
			vm.fetchMaterials().then(res => {
				vm.hiddenLoading()
			})
			if(vm.$route.name == 'Start') {
				vm.$router.push({name: 'Score'})
			}
		}).catch(error => {
			debugger
			console.log(error);
			if(code) {
				this.loading = true
				this.login({
					code
				}).then((res) => {	
					if(res.user.tour_complete) {
						
						if(vm.$route.name == 'Start') {
							vm.$router.push({name: 'Score'})
						}
						vm.fetchMaterials().then(res => {
							vm.hiddenLoading()
						})

					} else {
						
						vm.fetchMaterials().then(res => {
							vm.hiddenLoading()
						})
						vm.$router.push({name: 'Start'})
					}

					// vm.$refs.alertModal.open()

					return
				}).catch(() => {
					vm.hiddenLoading()
					vm.$router.push({name: 'SessionFail'})
				})

			}  else {
				vm.hiddenLoading()
				vm.$router.push({name: 'SessionFail'})
			}
		})

		
	 	
		// var images = [];
		// function preload(imageslist) {
		// 	for (var i = 0; i < imageslist.length; i++) {
		// 		images[i] = new Image();
		// 		images[i].src = imageslist[i];
		// 	}
		// }

		vm.checkSupport()

		//-- usage --//
		// preload([
		// 	"./assets/cara_robot_cargador.png",
		// 	"./assets/logo_zeus.png",
		// 	"./assets/pantalla_inicio/robot_pequeno_izquierda.png",
		// 	"./assets/pantalla_inicio/robot_pequeno_derecha.png",
		// 	"./assets/pantalla_inicio/robot_grande_central.png",
		// 	"./assets/pantalla_inicio/palabra_baz.png",
		// 	"./assets/pantalla_inicio/logo_zeus.png",
		// 	"./assets/pantalla_perfil_puntaje/insignia_principal.png",
		// 	"./assets/pantalla_perfil_puntaje/btn_2.png",
		// 	"./assets/pantalla_perfil_puntaje/btn_1.png",
		// 	"./assets/pantalla_perfil_puntaje/conteo_monedas.png",
		// ])


		
	}
}
</script>

<template>
  <div id="app">
	<button class="app-back" v-if="!['Start', 'Score', 'Download', 'UpOrder', 'Support', 'Maintenance', 'SessionFail', 'ErrorApp'].includes($route.name)" @click="$router.go(-1)">
		<img src="/assets/pantalla_perfil_puntaje/btn_2.png" alt="">
	</button>
	<button class="app-back" v-if="['Support'].includes($route.name)" @click="go('Score')">
		<img src="/assets/pantalla_perfil_puntaje/btn_2.png" alt="">
	</button>
	<button class="app-back app-btn-support" v-if="['Score'].includes($route.name) && user.help " @click="go('Support')">
		<img src="/img/support.png" alt="">
	</button>
	<!-- <button class="app-menu" v-if="!['Start', 'Download', 'Support', 'UpOrder', 'Maintenance', 'SessionFail', 'ErrorApp'].includes($route.name)" @click="handlerShowMenu">
		<img src="/assets/pantalla_perfil_puntaje/menu.png" alt="">
	</button> -->

	<Loader v-if="loading" ref="splashLoader"/>
	<modal-vue ref="alertModal">
			<template v-slot:header>
				<h5 class="m-0 text-center w-100">Recuerda</h5>
			</template>
			<template v-slot:body>
				<h6 class="text-center fw-bold mb-2">La etapa piloto termina el día hoy.</h6>
				<h6 class="mt-1"><span class="text-primary fw-bold">¡El juego y la posibilidad de ganar gemas y canjearlas continuan!</span></h6>
				<p class="text-center">Muy pronto conoceremos  la sucursal  <span class="text-primary fw-bold"> ganadora</span>.  Todos podemos <span class="text-primary fw-bold">seguir ganando gemas</span>. Muchas gracias por seguir participando y ¡A activar baz súperapp!</p>
				<div class="py-2 d-flex">
					<button class="btn btn--rounded btn-primary fw-bold me-auto ms-auto" @click="$refs.alertModal.close">Continuar</button>
				</div>
			</template>
	</modal-vue>
	<transition   name="slide"> 
		<router-view :key="$router.name"/>
	</transition>
	

	<div v-if="hasUpdate" class="forceUpdate">
		<div class="px-3">
			<div class="start--show">
				<div class="start__top">
					<img class="start__robot start__robot--front" src="/assets/pantalla_inicio/robot_grande_central.png" alt="">
				</div>
			</div>
			<div class="box p-2">
				<h6 class="text-center m-0">Tenemos una actualización.</h6>
				<div class="text-center">
					<button class="btn btn-primary btn--rounded text-white fw-bold mt-2" @click="handlerUpdate">ACTUALIZAR</button>
				</div>
			</div>
		</div>
	</div>
	<notifications position="top center" group="tc"/>
  </div>
</template>

<style lang="scss">
@import './assets/scss/app.scss';

</style>
<style lang="scss" scoped>
#app{
	max-width: 460px;
	margin: 0 auto;
}
.text-icon{
	font-weight: bold;
	font-size: 1.5rem;
}
.app-back{
	display: inline-block;
	&.app-btn-support{
		background: #0761d6;
		color: #fff;
		width: 2.2rem;
		height: 2.2rem;
		border-radius: 50%;
		animation:  buttonsupport 1.5s ease-in-out infinite;
		.text-icon{
			display: inline-block;
			transform-origin: center bottom;
			animation: textsupport 1.5s cubic-bezier(.36,.07,.19,.97) both infinite;
		}
		img{
			transform: rotate(0);
			width: 90%;
		}
	}
}

@keyframes buttonsupport{
	0%{
		box-shadow: 0 0 0 0px rgba($color: #0761d6, $alpha: 1.0);
	} 

	100%{
		box-shadow: 0 0 0 15px rgba($color: #0761d6, $alpha: 0);
	}
}

@keyframes textsupport {
  10%, 65% {
    transform: rotate(-1deg);
  }
  
  20%, 60% {
    transform: rotate(2deg);
  }

  30%, 50% {
    transform: rotate(-3deg);
  }

  40%, 60% {
    transform: rotate(3deg);
  }
}
.forceUpdate{
	position: fixed;
	background-color: rgba($color: #545999, $alpha: 0.7);
	backdrop-filter: blur(2px);
	z-index: 250;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.start__top{
	height: 35vh;
	margin-bottom: 0;
	
	.start__robot--front{
		bottom: -1rem;
	}
}

</style>