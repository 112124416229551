<script>
import { mapActions, mapGetters } from 'vuex'
import ModalVue from '../components/ModalVue.vue'
import CardActivations from '../components/CardActivations.vue'
import ActivationsRanking from './Challenges/ActivationsRanking.vue'
import TransactionsChallenge from './Challenges/TransactionsChallenge.vue'
import http from '../http'
export default {
	components: {
		ModalVue,
		CardActivations,
		ActivationsRanking,
		TransactionsChallenge
	},
	data() {
		return {
			savingSurvey: false,
			generalRank: 1000,
			totalGeneralRank: 3500,
			localRank: 800,
			totalLocalRank: 1500,
			swipe: null,
			swipeIndex: 0,
			slideVisibility: true,
			currentStep: 0,
			showBadges: false,
			slideClossing: false,
			endIntro: false,
			showIntro: false,
			introStep: 0,
			faq: false,
			surveys: [],
			challeng: null,
			gameClosed: {
				closed: true,
				message: ''
			},
			challenges: []
		}
	},

	computed: {
		progressGeneral() {
			return this.generalRank/this.totalGeneralRank*100
		},
		progressLocal() {
			return this.localRank/this.totalLocalRank*100
		},
		statsRegion() {
			return this.regionReferalsStats(this.user.id)
		},
		statsTerritory() {
			return this.territoryReferalsStats(this.user.id)
		},
		firstName() {
			
			if(this.user == null) return ''
			console.log(this.user.name)
			let name = this.user.name.split(' ')
			return name[name.length-1]			
		},

		intro() {
			if(!this.intros.length) {
				return null
			} 

			if(this.introStep > this.intros.length - 1) { 
				return null
			}

			let intro = this.intros[this.introStep]
			if(!intro) {
				return null
			}
			return intro
		},

		currentSurvey() {
			if(!this.surveys.length) {
				return null
			}
			return this.surveys[0]
		},

		...mapGetters({
			wallet: 'auth/wallet',
			user: 'auth/user',
			badge: 'auth/badge',
			regionReferalsStats: 'stats/regionReferalsStats',
			territoryReferalsStats: 'stats/territoryReferalsStats',
			agencyReferalsStats: 'stats/agencyReferalsStats',
			leaderRegionReferalsStats: 'stats/leaderRegionReferalsStats',
			leaderTerritoryReferalsStats: 'stats/leaderTerritoryReferalsStats',
			corpTerritoryReferalsStats: 'stats/corpTerritoryReferalsStats',
			territories: 'stats/territories',
			// intro: 'auth/intro',
			intros: 'auth/intros'
		})
	},

	watch: {
		intros: {
			handler(nval) {
				let vm = this
				// console.log(slideViewed)
				vm.showIntro = nval.length > 0
				if(vm.user.role_id != 6 || vm.user.role_id != 2) {
					if(!this.intro && !vm.user.tour_complete) {
						this.runTour()
					}
				}
			},
			immediate: true,
			deep: true
		}
	},

	methods: {
		async fetchChallenges() {
			let challenges = await http.challenges.current()
			this.challenges = challenges
			return Promise.resolve(challenges)
		},
		async getGameClosed() {
			let closed = await http.game.closed()
			this.$set(this, 'gameClosed', closed)
			return Promise.resolve(closed)
		},
		nextIntro() {
			let intro = this.intro;

			let materialsViewed = localStorage.getItem('materials_viewed')
			if(!materialsViewed) {
				materialsViewed = []
			} else {
				materialsViewed = JSON.parse(materialsViewed)
			}
			if(!intro.every_day) {
				materialsViewed.push(intro.id)
			}

			localStorage.setItem('materials_viewed', JSON.stringify(materialsViewed))

			if(this.introStep < this.intros.length - 1) {
				this.introStep += 1
			} else if(!this.user.tour_complete) {
				this.showIntro = false
				this.runTour()
			}  else {
				this.showIntro = false
			}


		},
		handlerEndIntro() {
			this.endIntro = true
		},
		runTour() {
			this.showIntro = false
			this.currentStep = 1
		},
		closeSlide() {
			let vm = this
			vm.slideClossing = true

			setTimeout(() => {
				vm.showBadges = false
				vm.swipeIndex = 0
			}, 1000)
		},
		floor(d) {
			return Math.floor(d)
		},
		nextStep() {
			let vm = this
			this.currentStep++
			if(this.user.role_id == 5 ) {
				if(this.currentStep == 4) {
					this.currentStep += 1
				}
			} else if(this.user.role_id == 4 && this.user.region_id) {
				if(this.currentStep == 4) {
					this.currentStep += 1
				}
			} else if(this.user.role_id == 4 && !this.user.region_id) {
				if(this.currentStep == 3) {
					this.currentStep += 1
				}
			}
			if(this.currentStep > 5) {
				this.currentStep = 0
				http.auth.tourComplete()
				let user = JSON.stringify(this.user)
				user = JSON.parse(user)
				user.tour_complete = 1
				// window.user = user
				sessionStorage.setItem('user', JSON.stringify(user))
				// document.querySelector('.app-back').classList.remove('d-none')
			}

			this.$nextTick(() => {
				try {
					
					let $step = document.getElementById('step' + vm.currentStep)
		
					if($step) {
						$step.scrollIntoView();
					}
				} catch (error) {
					
				}
			})

		},

		handlerShowBadges() {
			let vm =  this
			vm.showBadges = true
			vm.$nextTick(() => {
				var element = document.getElementById('slider');
				vm.slideClossing = false
				vm.swipe = new window.Swipe(element, {
					startSlide: 0,
					auto: 0,
					draggable: false,
					autoRestart: false,
					continuous: false,
					disableScroll: true,
					stopPropagation: true,
					callback: function(index, element) {
						vm.swipeIndex = index
					},
					transitionEnd: function(index, element) {}
				});
			})
		},
		calcBar(stats) {
			let point = 100 / stats.total_referals 
			return 	stats.me_referals * point
		},

		goTo(data) {
			this.$router.push(data)
		},
		async checkFaq() {
			try {
				let data = await http.params.get('show_faq_btn')
				this.faq = data.value
			} catch (error) {
				
			}
		},

		async fetchSurveys() {
			try {
				let data = await http.surveys.all()
				data.forEach(survey => {
					survey.questions.forEach(q => {
						q.data.answer = []
					})
				});
				this.$set(this, 'surveys', data)
			} catch (error) {
				
			}
		},

		async seveSurvey() {
			let payload = {
				survey_id: this.currentSurvey.id,
				questions: []
			}

			this.currentSurvey.questions.forEach(question => {
				let prepareData = {
					answer: [],
					aditional_answer: ''
				}

				prepareData.answer = question.data.answer
				if(question.data.answer.includes('aditional')) {
					prepareData.aditional_answer = question.data.aditional.answer
				}

				payload.questions.push({
					id: question.id,
					statement: question.statement,
					answer: prepareData.answer,
					aditional_answer: prepareData.aditional_answer
				})

			})

			try {
				if(this.savingSurvey) {
					return
				}
				this.savingSurvey = true
				let data = await http.surveys.complete(payload)
				this.savingSurvey = false
				this.$refs.survey.close()
				this.fetchSurveys()
			} catch (error) {
				
			}
		},

		async getChalleng() {
			try {
				let data = await http.challenges.current()
				this.$set(this, 'challeng', data )
			} catch (error) {
			}
		},
		...mapActions({
			fetchUser: 'auth/fetchUser',
			fetchRegionReferals: 'stats/fetchRegionReferals',
			fetchTerritoryReferals: 'stats/fetchTerritoryReferals',
			fetchAgencyReferals: 'stats/fetchAgencyReferals',
			fetchTerritories: 'stats/fetchTerritories',
			fetchNotifications: 'auth/fetchNotifications'
		})
	},

	async mounted() {
		// this.getChalleng()
		this.fetchSurveys()

		// this.checkFaq()
		let session = await this.fetchUser()
		// let challenges = await this.fetchChallenges()
		let gameClosed = await this.getGameClosed()
		if(this.gameClosed.closed == true) {
			return
		} else {
			console.log('sigue')
			let now = this.$moment()
			console.log(now)
			let last_activations_update = localStorage.last_activations_update ? this.$moment(localStorage.last_activations_update) : null
			
	
			if(this.user.role_id == 6 || this.user.role_id == 2 || this.user.role_id == 9) {
				this.fetchTerritories()
			}
	
			if(last_activations_update) {
				var duration = this.$moment.duration(now.diff(last_activations_update));
				console.log(now)
				console.log(last_activations_update)
				console.log(duration.asMinutes())
		
				// localStorage.last_activations_update = now
				
				if(duration.asMinutes() < 20) {
					return 
				} 
			}
	
	
	
			
			// this.fetchNotifications()
			if(this.user.role_id == 3) {
				this.fetchRegionReferals().then(r => {
					localStorage.last_activations_update = now
				})
				this.fetchTerritoryReferals().then(r => {
					localStorage.last_activations_update = now
				})
			} else if(this.user.role_id == 5) {
				this.fetchAgencyReferals().then(r => {
					localStorage.last_activations_update = now
				})
			} else if(this.user.role_id == 4) {
				if(this.user.region_id) {
					this.fetchRegionReferals().then(r => {
						localStorage.last_activations_update = now
					})
				} else if (this.user.territory_id) {
					this.fetchTerritoryReferals().then(r => {
						localStorage.last_activations_update = now
					})
				}
			} 
	
	
			var element = document.getElementById('slider');
		}
	
		
		
	},

}
</script>
<template>
	<div class="score" v-if="user">
		<modal-vue ref="survey" v-if="currentSurvey" :opening="true" :can-close="false">
			<template v-slot:header>
				<h5 class="m-0">{{currentSurvey.title}}</h5>
			</template>
			<template v-slot:body>
				<p class="text-center">{{currentSurvey.description}}</p>
				<div v-for="(question, indexq) in currentSurvey.questions" :key="`q-${indexq}`">
					<div>
						<div class="col-12" v-if="question.type == 'text'">
							<div class="vault__search flex-wrap mt-1">
								<label class="w-100 d-block ms-2" for="">{{indexq + 1}} - {{question.statement}}</label>
								<div class="vault__search-input">
									<input type="text" v-model="question.data.answer">
								</div>
							</div>
						</div>
						<div class="col-12" v-else>
							<div class="vault__search flex-wrap mt-1">
								<h6 class="w-100 d-block" for="">{{indexq + 1}} - {{question.statement}}</h6>
								<div class="ps-3">
									<label v-for="(option, index) in question.data.options" :key="`op-${index}`" class="d-block">
										<input :type="question.type" :name="`option-select-${indexq}`" v-model="question.data.answer" :value="option.name">
										<span class="ms-2 text-bold" style="font-weight: bold; font-size: 0.9rem">{{option.name}}</span>
									</label>
									<label v-if="question.data.aditional.active" class="d-block">
										<input :type="question.type" :name="`option-select-${indexq}`" v-model="question.data.answer" :value="`aditional`">
										<span class="ms-2 text-bold" style="font-weight: bold; font-size: 0.9rem">{{question.data.aditional.statement}}</span>
									</label>
								</div>
							</div>
						</div>
						<div class="col-12" v-if="question.data.answer.includes('aditional')">
							<div class="vault__search flex-wrap mt-1">
								<div class="vault__search-input">
									<input type="text" v-model="question.data.aditional.answer">
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="py-2 d-flex">
					<button class="btn btn--rounded btn-primary fw-bold me-auto ms-auto" @click="seveSurvey">Continuar</button>
				</div>
			</template>
		</modal-vue>
		<template v-if="!(showIntro ? intro.type == 'video' : false)">
		<div id="step1" class="p-0" :class="{
			'score__step-active': currentStep == 1
		}">
			<div class="score__wellcome text-center pb-3">
				<h2 class="sub-title mb-0 text-white">Bienvenido {{firstName}}.</h2>
				<h5 class=" mb-0 text-white small">{{user.code}}</h5>
				<h3 v-if="user.role_id == 3" class="title m-0">
					Activador
				</h3>
				<h3 v-else-if="user.role_id == 4" class="title m-0">
					Promotor
				</h3>
				<h3 v-if="user.role_id == 5" class="title m-0">
					Impulsor
				</h3>
				<h3 v-if="user.role_id == 6" class="title m-0">
					Corporativo
				</h3>
				<h3 v-if="user.role_id == 2" class="title m-0">
					Corporativo
				</h3>
				<h3 v-if="user.role_id == 9" class="title m-0">
					Corporativo
				</h3>
			</div>
			<!-- <div class="score__step-tip step__1" >
				<img src="/assets/steps/1.png" alt="">
			</div> -->
		</div>
		<!-- <div class="bg-primary text-white p-2 small text-center" role="alert">
			<p>¡Tus activaciones están seguras y las verás reflejadas junto con tus gemas muy pronto!</p>
			<p class="m-0">Sigue activando y recuerda, <span class="fw-bold"> con baz súperapp ¡Vamos por todo, Todos!</span></p>
		</div> -->

		<div class="score__wrapper ">
			<!-- FINALDELJUEGO -->
			<!-- <div v-if="user" >
				<div v-if="user.role_id == 3" class="box p-3 mt-3 text-center">
					<h6 class="m-0" v-if="user.daily_activations == 0"><span class="fw-bold">¡Vamos con todo!</span> a activar este día.</h6>
					<h6 class="m-0" v-else-if="user.daily_activations == 1">Has logrado  <span class="fw-bold"> {{user.daily_activations}} activación hoy</span></h6>
					<h6 class="m-0" v-else-if="user.daily_activations < 4">Has logrado  <span class="fw-bold"> {{user.daily_activations}} activaciones hoy</span></h6>
					<h6 class="m-0" v-else-if="user.daily_activations == 4"><span class="fw-bold">¡Vamos!</span> solo te falta una activación para ganar <span class="fw-bold text">1 gema</span> llevas <span class="fw-bold"> {{user.daily_activations}} activaciones hoy</span></h6>
					<h6 class="m-0" v-else-if="user.daily_activations == 5"><span class="fw-bold">¡Wow!</span> lograste ganar tu primera gema del día llevas <span class="fw-bold"> {{user.daily_activations}} activaciones hoy</span> no pares de activar para seguir ganando</h6>
					<h6 class="m-0" v-else><span class="fw-bold">¡Wow!</span> has ganado <span class="fw-bold text">{{floor(user.daily_activations / 5)}} Gemas</span> y llevas <span class="fw-bold"> {{user.daily_activations}} activaciones </span> este día, no pares de activar para seguir ganando</h6>
				</div>
			</div> -->
			<!-- FINALDELJUEGO -->

			<!-- <div v-if="currentStep" class="score__overlay"></div> -->

			<div id="step2" class="score__badge" :class="{
					'score__step-active': currentStep == 2
				}">
				<img v-if="badge"  class="score__badge-medal" :src="`/img/badges/diamante_plata.png`" alt="" @click="handlerShowBadges">
				<img class="score__badge-bg" src="/assets/v2/badge_bg.png">
				
				<!-- <div class="score__step-tip step__2" >
					<img src="/assets/steps/2.png" alt="">
				</div> -->
			</div>
			<div class="px-2 pt-1 pb-1 mt-5">

				<div v-if="challenges.length">
					<div v-for="(challenge, index) in challenges" :key="`challenge-${index}`">
						<component :is="challenge.component" :challenge="challenge"></component>
					</div>
				</div>
				<div v-else-if="gameClosed.closed == true" class="box-diamante px-3 pb-3 pt-3">
					<p class="text-center" v-html="gameClosed.message"></p>
				</div>
				<div v-else>
					<div v-if="user.role_id == 3"  class="box-diamante px-0 pb-3 pt-3">
						<div class="py-2  px-3"  :class="{
							'score__step-active ': currentStep == 3
						}">
							<p v-if="user" class="text-center">Última actualización <br>
							 {{user.last_s3_update | moment("dddd h:mm A")}}</p>
							<div class="score__progress-bar">
								
								<div class="progress">
									<div class="progress-bar progress-bar-striped bg-special bg-no-animate" role="progressbar" :style="{
										width: `${calcBar(statsRegion)}%`
									}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
								</div>
								<router-link :to="{name: 'RegionalRanking'}" class="score__progress-button">
									<img src="/assets/pantalla_perfil_puntaje/score_purple.png" alt="">
								</router-link>
							</div>
							<div class="score__progress pt-2">
								<div>
									<h5>
										<img class="score__medal" v-if="[1,2,3].includes(statsRegion.position)" :src="`/assets/medals/${statsRegion.position}_place.png`"/>
										<span class="fw-bold">{{statsRegion.me_referals ? '#' + statsRegion.position : 'Sin activaciones'}}</span>
									</h5>
									<p class="m-0">Ranking Regional</p>
								</div>
								
								<div class="text-end">
									<h5 class="fw-bold">
										{{statsRegion.me_referals}}/{{statsRegion.total_referals}}
									</h5>
									<p class="m-0">Activaciones</p>
								</div>
							</div>
							<div class="text-center pt-2 mb-2">
								<router-link class="btn btn-primary btn-sm btn--rounded fw-bold text-uppercase" :to="{name: 'RegionalRanking'}">Ver posiciones</router-link> 
							</div>
							<div  id="step3" class="score__step-tip step__3" >
								<img src="/assets/steps/3.png" alt="">
							</div>
						</div>
						<div  id="step4" class="py-2  px-3" :class="{
						'score__step-active': currentStep == 4
						}">
							<div class="score__progress-bar">
								
								<div class="progress">
									<div class="progress-bar progress-bar-striped bg-special-2 bg-no-animate" role="progressbar" :style="{
										width: `${calcBar(statsTerritory)}%`
									}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
								</div>
								<router-link :to="{name: 'TerritorialRanking'}" class="score__progress-button">
									<img src="/assets/pantalla_perfil_puntaje/score_green.png" alt="">
								</router-link>
							</div>
							<div class="score__progress pt-2">
								<div>
									<h5>
										<img class="score__medal" v-if="[1,2,3].includes(statsTerritory.position)" :src="`/assets/medals/${statsTerritory.position}_place.png`"/>
										<span class="fw-bold">{{statsTerritory.me_referals ? '#' + statsTerritory.position : 'Sin activaciones'}}</span> 
									</h5>
									<p class="m-0">Ranking Territorial</p>
								</div>
								<div class="text-end">
									<h5 class="fw-bold">
										{{statsTerritory.me_referals}}/{{statsTerritory.total_referals}}
									</h5>
									<p class="m-0">Activaciones</p>
								</div>
							</div>
							<div class="text-center pt-2 mb-2">
								<router-link class="btn btn-success btn-sm btn--rounded text-white fw-bold text-uppercase" :to="{name: 'TerritorialRanking'}">Ver posiciones</router-link> 
							</div>
							
							<div class="score__step-tip step__4" >
								<img src="/assets/steps/4.png" alt="">
							</div>
						</div>
					</div>
					<div v-else-if="user.role_id == 5" class="box-diamante px-0 pb-3 pt-3">
						<div  class="py-2  px-3"  :class="{
							'score__step-active ': currentStep == 3
						}">
							<p v-if="user" class="text-center">Última actualización <br>
							 {{user.last_s3_update | moment("dddd h:mm A")}}</p>
							<div id="step3" class="score__step-tip step__3" >
								<img src="/assets/steps/3.png" alt="">
							</div>
							<h6 class="text-center">Tu Punto de Venta ha logrado  
								<span class="fw-bold d-block"> <span class="text-white">{{agencyReferalsStats.daily_activations}} activaciones </span>  hoy</span>  
							</h6>
							<div class="score__progress-bar">
								
								<div class="progress">
									<div class="progress-bar progress-bar-striped bg-special bg-no-animate" role="progressbar" :style="{
										width: `${ agencyReferalsStats.weekly_activations / user.weekly_goal * 100  }%`
									}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
								</div>
								<router-link :to="{name: 'AgencyRanking'}" class="score__progress-button">
									<img src="/assets/pantalla_perfil_puntaje/score_purple.png" alt="">
								</router-link>
							</div>
							<div class="score__progress pt-2">
								<div>
									<h5>
										<span class="fw-bold">Meta semanal</span>
									</h5>
								</div>
								
								<div class="text-end">
									<h5 class="fw-bold">
										{{ agencyReferalsStats.weekly_activations}}/{{ user.weekly_goal}}
									</h5>
									<p class="m-0 fw-bold">Activaciones</p>
								</div>
							</div>
							<div class="text-center pt-2 mb-2">
								<router-link class="btn btn-primary btn-sm btn--rounded fw-bold text-uppercase" :to="{name: 'AgencyRanking'}">Ver posiciones</router-link> 
							</div>
							<h6 class="text-center mt-2 small">Motiva y ayuda a los activadores a alcanzar su meta, si todos lo logran,  <span class="fw-bold text"> tú ganas</span></h6>
							
						</div>
					</div>	
					<div v-else-if="user.role_id == 4 && user.region_id" class="box-diamante px-0 pb-3 pt-3">
						<div  class="py-2  px-3"  :class="{
							'score__step-active ': currentStep == 3
						}">
							<p v-if="user" class="text-center">Última actualización <br>
							 {{user.last_s3_update | moment("dddd h:mm A")}}</p>
							<h6 class="text-center">Tu region ha logrado  
								<span class="fw-bold d-block"> <span class="text">{{leaderRegionReferalsStats.daily_activations}}  activaciones</span>  hoy</span>  
							</h6>
							<div class="score__progress-bar">
								
								<div class="progress">
									<div class="progress-bar progress-bar-striped bg-special bg-no-animate" role="progressbar" :style="{
										width: `${ leaderRegionReferalsStats.weekly_activations / user.weekly_goal * 100  }%`
									}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
								</div>
								<router-link :to="{name: 'CorpRegionRanking', params: {id: user.region_id}}" class="score__progress-button">
									<img src="/assets/pantalla_perfil_puntaje/score_purple.png" alt="">
								</router-link>
							</div>
							<div class="score__progress pt-2">
								<div>
									<p class="m-0">Meta semanal</p>
								</div>
								
								<div class="text-end">
									<h5 class="fw-bold">
										{{ leaderRegionReferalsStats.weekly_activations}}/{{ user.weekly_goal}}
									</h5>
									<p class="m-0">Activaciones</p>
								</div>
							</div>
							<div class="text-center pt-2 mb-2">
								<router-link class="btn btn-primary btn-sm btn--rounded fw-bold text-uppercase" :to="{name: 'CorpRegionRanking', params: {id: user.region_id}}">Ver posiciones</router-link> 
							</div>
							<h6 class="text-center mt-2 small">Motiva y ayuda a los activadores a alcanzar su meta, si todos lo logran,  <span class="fw-bold text"> tú ganas</span></h6>
							<div id="step3" class="score__step-tip step__3" >
								<img src="/assets/steps/3.png" alt="">
							</div>
						</div>
					</div>
					<div v-else-if="user.role_id == 4 && !user.region_id" class="box-diamante px-0 pb-3 pt-3">
						<div id="step4" class="py-2 px-3"  :class="{
							'score__step-active ': currentStep == 4
						}">
							<p v-if="user" class="text-center">Última actualización <br>
							 {{user.last_s3_update | moment("dddd h:mm A")}}</p>
							<h6 class="text-center">Tu territorio ha logrado  
								<span class="fw-bold d-block"> <span class="text">{{leaderTerritoryReferalsStats.daily_activations}} activaciones</span>  hoy</span>  
							</h6>
							<div class="score__progress-bar">
								
								<div class="progress">
									<div class="progress-bar progress-bar-striped bg-special bg-no-animate" role="progressbar" :style="{
										width: `${ leaderTerritoryReferalsStats.weekly_activations /user.weekly_goal * 100  }%`
									}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
								</div>
								<router-link :to="{name: 'CorpTerritoryRanking', params: {id:  user.territory_id}}" class="score__progress-button">
									<img src="/assets/pantalla_perfil_puntaje/score_purple.png" alt="">
								</router-link>
							</div>
							<div class="score__progress pt-2">
								<div>
									<p class="m-0">Meta semanal</p>
								</div>
								
								<div class="text-end">
									<h5 class="fw-bold">
										{{ leaderTerritoryReferalsStats.weekly_activations}}/{{ user.weekly_goal }}
									</h5>
									<p class="m-0">Activaciones</p>
	
								</div>
							</div>
							<div class="text-center pt-2 mb-2">
								<router-link class="btn btn-primary btn-sm btn--rounded fw-bold text-uppercase" :to="{name: 'CorpTerritoryRanking', params: {id:  user.territory_id}}">Ver posiciones</router-link> 
							</div>
							<h6 class="text-center mt-2 small">Motiva y ayuda a los activadores a alcanzar su meta, si todos lo logran,  <span class="fw-bold text"> tú ganas</span></h6>
							<div class="score__step-tip step__4" >
								<img src="/assets/steps/4.png" alt="">
							</div>
						</div>
					</div>
					<div v-else-if="user.role_id == 6 || user.role_id == 2 || user.role_id == 9" class=" px-0 pb-3 mt-3">
						<p v-if="user" class="text-center">Última actualización <br>
							 {{user.last_s3_update | moment("dddd h:mm A")}}</p>
						<div v-if="territories.length">
							<CardActivations 
								v-for="(territory, index) in territories" 
								:key="`t_${territory.id}_${index}`"
								:title="territory.name"
								:activators="territory.users"
								:total="territory.activations"
								:now="territory.activations_now"
								:weekly="territory.activations_weekly"
								:weekly_goal="territory.weekly_goal"
								:route="'CorpTerritoryRanking'"
								:id="territory.id"
							/>
						</div>
						<div class="d-flex" v-else>
							<div class="mx-auto">
								<div class="lds-ripple"><div></div><div></div></div>
								<p class="text-center text-white">Cargando...</p>
							</div>
						</div>
					</div>
	
					<div v-if="[3,5].includes(user.role_id) && challeng" class="my-3">
						<h4 class="text-center text-primary">{{challeng.title}}</h4>
						<div  class="box-diamante px-0 pb-3 pt-3 ">
							<div class="px-3 text-center">
								<p>
									Desde la {{challeng.history_week_start}} a la {{challeng.history_week_end}} acumulaste {{challeng.base}} activaciones, para lograr el reto debes superar  {{challeng.base}} + {{challeng.additional_percentage}}%  sumando un total de {{challeng.goal}} activaciones
								</p>
							</div>
							<div class="py-2  px-3">
								<div class="score__progress-bar">
									<div class="progress">
										<div class="progress-bar progress-bar-striped bg-special-2 bg-no-animate" role="progressbar" :style="{
											width: `${challeng.referlas / challeng.goal * 100}%`
										}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
									</div>
									<span class="score__progress-button">
										<img src="/assets/pantalla_perfil_puntaje/score_green.png" alt="">
									</span>
								</div>
		
								<div class="score__progress pt-2">
									<div class="text-center">
										<h4 class="m-0">
											<span class="fw-bold">{{challeng.referlas}}</span> 
										</h4>
										<p class="m-0">Acumulado <br> S{{challeng.challeng_week_start}} a S{{challeng.challeng_week_end}}</p>
									</div>
									<div class="text-center">
										<h4 class="fw-bold m-0">
											{{challeng.goal}}
										</h4>
										<p class="m-0">Meta <br> {{challeng.base}} + {{challeng.additional_percentage}}% </p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				
				<!-- <div class="box p-3 mt-3 text-center">
					<h5>Gracias por participar en el Juego de gemas.</h5>
					<p>El juego ha finalizado, estamos analizando los datos y pronto tendremos noticias.</p>
					<p>Recuerda que podras canjear tus gemas el jueves.</p>
				</div> -->


				

				
				<div v-if="wallet && (user.role_id != 6 || user.role_id != 2)" id="step5" class="mt-3 relative score__wallet" :class="{ 
					'score__step-active ': currentStep == 5
					}">

					<div v-if="wallet && (user.role_id != 6 || user.role_id != 2)" class="score__coinsn">
						<img src="/img/gem.png" alt="" style="width: 20px;margin-left: -25px; margin-top: -5px;">
						<span class="score__coinsn-text">{{wallet.balance}}</span>
						<!-- <img class="score__coinsn-bg" src="/assets/pantalla_perfil_puntaje/conteo_monedas.png" alt=""> -->
					</div>

					<p class="m-0 text-center score__wallet-text">
						Tienes <span class="fw-bold" v-if="wallet && (user.role_id != 6 || user.role_id != 2)">{{wallet.balance}} esmeraldas</span> canjeables en <span class="text-success">bóveda <span class="fw-bold">baz</span></span>
					</p>

					<div class="score__wallet-bg">
						<img src="/assets/v2/wallet_bg.png" alt="">
					</div>

					<!-- <div class="score__step-tip step__5" >
						<img src="/assets/steps/5.png" alt="">
					</div> -->
				</div>
				<div v-if="wallet" class="text-center mt-3 mb-5">
					<router-link :to="{name: 'Vault'}" class="btns" style="width: 250px">
						<span class="btns__text">
							VER LOS PREMIOS
						</span>
						<img class="btns__bg" src="/assets/v2/boton_rosa.png" alt="">
					</router-link>
				</div>

				<!-- <div class="score__logo mt-3 mb-5">
					<img src="/assets/logo_zeus_.png" alt="">
				</div> -->
			</div>
		</div>

		<div v-if="currentStep" class="score__overlay-glass"  @click="nextStep">
			<div class="tap-idicator"></div>
			<div class="tap-idicator2"></div>
		</div>
		<!-- <div v-if="showBadges" id="slider" class="swipe" :class="{'swipe--closing': slideClossing}">
			<div class="swipe-wrap">
				<div>
					<div class="p-3">
						<div class="swipe__badge">
							<img :src="bronce" alt="">
						</div>
						<div class="box text-center p-2 py-3 pt-4">
							<h1 class="title m-0">Insignia bronce</h1>
							<p class="small fw-bold m-0">Primera activación</p>
							<p>
								Registra tu primera activación y gana gemas al cumplir tu meta diara.
							</p>
						</div>
					</div>
				</div>
				<div>
					<div class="p-3">
						<div class="swipe__badge">
							<img :src="silver" alt="">
						</div>
						<div class="box text-center p-2 py-3 pt-4">
							<h1 class="title m-0">Insignia plata</h1>
							<p class="mb-0"> <span class="small fw-bold">250 Activaciones</span></p>

							<p>
								Activa 250 o más y gana un <span class="fw-bold">bono del 5%</span> de tus gemas. 
							</p>
						</div>
					</div>
				</div>
				<div>
					<div class="p-3">
						<div class="swipe__badge">
							<img :src="blue" alt="">
						</div>
						<div class="box text-center p-2 py-3 pt-5">
							<h1 class="title m-0">Insignia oro</h1>
							<p class="mb-0"> <span class="small fw-bold">500 Activaciones</span></p>
							<p>
								Activa 500 o más y gana un <span class="fw-bold">bono del 10%</span> de tus gemas. 
							</p>
						</div>
					</div>
				</div>
				
			</div>

			<div class="swipe__dots">
				<span v-for="(c, index) of 3" :key="`dd_${index}`" :class="{'active': index == swipeIndex}"></span>
				<div class="w-100 text-center">
					<button @click="closeSlide" class="btn btn-success btn--rounded text-white btn-sm px-3 mt-2">ACEPTAR</button>
				</div>
			</div>

			<button class="app-back" @click="closeSlide">
				<img src="/assets/pantalla_perfil_puntaje/score_purple.png" alt="">
			</button>
	
		</div> -->
		<div v-if="showIntro">
			<div v-if="intro.type == 'video'" class="score__overlay">
				<div class="score_video">
					<h6 class="text-center text-white">{{intro.title}}</h6>
					<div class="d-flex">
						<div v-if="!intro.is_vertical" class="mx-auto" style="max-width: 100%; min-width: 90%">
							<div style="padding:56.25% 0 0 0;position:relative;"><iframe :src="intro.url"  frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" :title="intro.title"></iframe></div>
						</div>
						<div v-else class="mx-auto" style="max-width: 100%; min-width: 90%">
							<div >
								<iframe :src="intro.url"  frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="width:100%;height:70vh;" :title="intro.title"></iframe>
							</div>
						</div>
					</div>
					<div class="text-center py-2">
						<button  @click="nextIntro" class="btn btn-primary fw-bold btn--rounded">Continuar</button>
					</div>
				</div>
			</div>
			<div v-else-if="intro.type == 'html'">
				<modal-vue ref="tipModal" :opening="true" :can-close="false">
					<template v-slot:header>
						<h5 class="m-0">{{intro.title}}</h5>
					</template>
					<template v-slot:body>
						<div v-html="intro.content"></div>
						<div class="py-2 d-flex">
							<button class="btn btn--rounded btn-primary fw-bold me-auto ms-auto" @click="nextIntro">Continuar</button>
						</div>
					</template>
				</modal-vue>
			</div>
		</div>

		<modal-vue ref="alertModal">
			<template v-slot:header>
				<h5 class="m-0">Recuerda</h5>
			</template>
			<template v-slot:body>
				<h6 class="text-center fw-bold">Recuerda que hoy finaliza esta etapa del juego. </h6>
				<p class="text-center">Apresúrate a activar hasta las 23:59 del día de hoy y acumular más gemas.  <span class="text fw-bold">¡Mucho éxito!</span></p>
				<div class="py-2 d-flex">
					<button class="btn btn--rounded btn-primary fw-bold me-auto ms-auto" @click="$refs.alertModal.close">Continuar</button>
				</div>
			</template>
		</modal-vue>

		<button @click="goTo({name: 'Help', params: {tab: 'faq'}})"  v-if="faq" class="btn btn-primary btn-sm btn--rounded btn-add-ticket"><span>?</span></button>
		</template>
		<template v-else>
				<div>
					<div class="score__wellcome text-center pb-3">
					<h2 class="sub-title mb-0 text-white">Bienvenido {{firstName}}.</h2>
					<h5 class=" mb-0 text-white small">{{user.code}}</h5>
				</div>
				<div class="score_video" style="z-index: 99; position: relative; margin-top: 50px">
					<h6 class="text-center title " style="font-size: 1rem">{{intro.title}}</h6>
					<div class="d-flex">
						<div v-if="!intro.is_vertical" class="mx-auto" style="max-width: 100%; min-width: 90%">
							<div style="padding:56.25% 0 0 0;position:relative;"><iframe :src="intro.url"  frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" :title="intro.title"></iframe></div>
						</div>
						<div v-else class="mx-auto" style="max-width: 100%; min-width: 90%">
							<div >
								<iframe :src="intro.url"  frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="width:100%;height:70vh;" :title="intro.title"></iframe>
							</div>
						</div>
					</div>
					<div class="text-center py-2">
						<button  @click="nextIntro" class="btn btn-primary fw-bold btn--rounded">Continuar</button>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>

<style lang="scss" scoped>
.score_video{
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	video{
		max-height: 40vh;
	}
}

.btn-add-ticket{
    position: fixed;
    bottom: 30px;
    right: 30px;
    -webkit-animation: buttonsupport2 1.5s ease-in-out infinite;
    animation: buttonsupport2 1.5s ease-in-out infinite;
	z-index: 2;
	border-radius: 50%;
	height: 2.5rem;
	width: 2.5rem;
}
@keyframes buttonsupport2{
	0%{
		box-shadow: 0 0 0 0px rgba($color: #6807d6, $alpha: 1.0);
	} 

	100%{
		box-shadow: 0 0 0 15px rgba($color: #6807d6, $alpha: 0);
	}
}

.btn-add-ticket span{
    font-weight: bold;
    font-size: 20px;
	display: inline-block;
}
</style>