function getFromLocalStorage(key) {
	return localStorage[key] ? JSON.parse(localStorage[key]) : []
}

export default {
	regionReferals: getFromLocalStorage('regionReferals'),
	territoryReferals: getFromLocalStorage('territoryReferals'),
	agencyReferals: getFromLocalStorage('agencyReferals'),
	territories: getFromLocalStorage('territories'),
}

