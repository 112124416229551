<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { Carousel, Slide } from 'vue-carousel';
import http from '../http'
import ModalVue from '../components/ModalVue.vue'
export default {
	components: {
		Carousel,
		Slide,
		ModalVue,
	},
	data() {
		return {
			product: null
		}
	},
	computed: {
		

		statsRegion() {
			return this.regionReferalsStats(this.user.id)
		},
		...mapGetters({
			wallet: 'auth/wallet',
			user: 'auth/user',
			productBySku: 'vault/productBySku'
		})
	},
	methods: {
		async getDetails() {
			let sku = this.$route.params.sku

			let product = this.productBySku(sku)

			if(!product) {
				this.getLocalProducts().then(res => {
					this.loading = false
					product = this.productBySku(sku)
					this.$set(this, 'product', product)
				}).catch(err => {
					this.loading = false
				}) 
			} else {
				this.$set(this, 'product', product)
			}

			
		},
		getShippingCost(price) {
			return 0
			if(price <= 1000) {
				return 1;
			} else if(price <= 5000) {
				return 2;
			} else {
				return 3;
			}
		},
	
		calcGems(price) {
			let shippingCost = this.getShippingCost(price)
			return Math.ceil(price / 102) + shippingCost
		},
		upOrder() {
			this.$refs.upOrder.open()
		},
		setProduct() {
			this._setProduct(this.product)
			this.$router.push({name: 'UpOrder'})
		},
		...mapActions({
			fetchUser: 'auth/fetchUser',
			getLocalProducts: 'vault/getLocalProducts'
		}),
		...mapMutations({
			_setProduct: 'vault/setProduct'
		})
	}, 
	mounted() {
		document.querySelector('html').scrollTop = 0
		this.fetchUser()
		this.getDetails()
	}
}
</script>
<template>
	<div class="ranking" v-if="user && product">
		<div v-if="product.type == 'elektra'">
			<div class="vault__header">
				<div class="vault__header-content">
					<div class="vault__header-wrapper flex-wrap text-center w-100 ">
						<h6 v-if="product" class="w-100 text-center m-0">
							<span class="me-1">{{product.elektra.ProductName}}</span>
						</h6>
					</div>
				</div>
			</div>
			<div class="px-2">
				<div class="alert alert-primary mt-3" v-if="!user.vault_open">
					<h6 class="text-center">Bóveda cerrada, regresamos pronto.</h6>
				</div>
				<div class="box my-3 p-0 overflow-hidden"  v-if="product">
					<carousel :per-page="1">
						<slide v-for="(image, index) in product.elektra.Images" :key="`img_${index}_${image.FileId}`">
							<img :src="forceHTTPS(image.ImageUrl)" class="w-100" alt="">
						</slide>
					
					</carousel>	

					<div class="text-center py-2">
						<h6 v-if="calcGems(product.elektra.Price) >  wallet.balance || !user.vault_open" class="text-primary">
							Precio G{{ calcGems(product.elektra.Price)}}
						</h6>
						<button v-else class="btn btn-primary btn--rounded fw-bold mx-auto"
							@click="upOrder"
							:disabled="calcGems(product.elektra.Price) >  wallet.balance"
							>G{{ calcGems(product.elektra.Price)}} CANJEAR </button>
						<p class="text-danger m-0 small" v-if="calcGems(product.elektra.Price) >  wallet.balance">
							No tienes gemas suficientes.
						</p>
						
					</div>
					<div class="p-3" v-html="product.elektra.ProductDescription">
						
					</div>
					<div class="text-center py-2 pb-3">
						<h6 v-if="calcGems(product.elektra.Price) >  wallet.balance || !user.vault_open" class="text-primary">
							Precio G{{ calcGems(product.elektra.Price)}}
						</h6>
						<button v-else class="btn btn-primary btn--rounded fw-bold mx-auto"
							@click="upOrder"
							:disabled="calcGems(product.elektra.Price) >  wallet.balance || !user.vault_open"
						>G{{ calcGems(product.elektra.Price)}} CANJEAR </button>
						<p class="text-danger m-0 small" v-if="calcGems(product.elektra.Price) >  wallet.balance">
							No tienes gemas suficientes.
						</p>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<div class="vault__header">
				<div class="vault__header-content">
					<div class="vault__header-wrapper flex-wrap text-center w-100 ">
						<h6 v-if="product" class="w-100 text-center m-0">
							<span class="me-1">{{product.name}}</span>
						</h6>
					</div>
				</div>
			</div>
			<div class="px-2">
				<div class="alert alert-primary mt-3" v-if="!user.vault_open">
					<h6 class="text-center">Bóveda cerrada, regresamos pronto.</h6>
				</div>
				<div class="box my-3 p-0 overflow-hidden"  v-if="product">
					<carousel :per-page="1">
						<slide v-for="(image, index) in product.gallery" :key="`img_${index}`">
							<img :src="forceHTTPS(image)" class="w-100" alt="">
						</slide>
					</carousel>	

					<div class="text-center py-2">
						<h6 v-if="product.gems >  wallet.balance || !user.vault_open" class="text-primary">
							Precio G{{ product.gems}}
						</h6>
						<button v-else class="btn btn-primary btn--rounded fw-bold mx-auto"
							@click="upOrder"
							:disabled="product.gems >  wallet.balance"
							>G{{ product.gems}} CANJEAR </button>
						<p class="text-danger m-0 small" v-if="product.gems >  wallet.balance">
							No tienes gemas suficientes.
						</p>
						
					</div>
					<div class="p-3" v-html="product.description">
						
					</div>
					<div class="text-center py-2 pb-3">
						<h6 v-if="product.gems >  wallet.balance || !user.vault_open" class="text-primary">
							Precio G{{ product.gems}}
						</h6>
						<button v-else class="btn btn-primary btn--rounded fw-bold mx-auto"
							@click="upOrder"
							:disabled="product.gems >  wallet.balance || !user.vault_open"
						>G{{ product.gems}} CANJEAR </button>
						<p class="text-danger m-0 small" v-if="product.gems >  wallet.balance">
							No tienes gemas suficientes.
						</p>
					</div>
				</div>
			</div>
		</div>
		<modal-vue ref="upOrder">
			<template v-slot:header>
				<h5 class="m-0">Aviso</h5>
			</template>
			<template v-slot:body>
				<p class="small">Antes de continuar debes aceptar los terminos y condiciones.</p>
				<h6>Consideraciones:</h6>
				<ul class="small">
					<li class="mb-1">No se permite retiro en tienda solo envíos.</li>
					<li class="mb-1">No se aceptan devoluciones.</li>
					<li class="mb-1">No se reembolsán gemas.</li>
					<li class="mb-1">No se reembolsa efectivo.</li>
					<li class="mb-1">Si el artículo esta dañado se permite cambio por otro similar.</li>
					<li class="mb-1">No se aceptan cancelaciones en la entrega y se harán dos intentos para la misma.</li>
				</ul>
				<div class="py-2 d-flex">
					<button class="btn ms-auto" @click="$refs.upOrder.close">Cancelar</button>
					<button class="btn btn--rounded btn-primary fw-bold me-auto" @click="setProduct">Aceptar 
						<span class="d-none d-md-inline-block">
							y continuar
						</span>
					</button>
				</div>
			</template>
		</modal-vue>
	</div>
</template>

<style lang="scss" scoped>
.start__top{
	height: 35vh;
	margin-bottom: 0;
	.start__robot--front{
		bottom: -1rem;
	}
}
</style>